import { LoginRes, QrCode } from './../shared/interfaces/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MFA_SETUP_STEPS } from '../shared/interfaces/common';

@Component({
  selector: 'lo-mfa-setup',
  templateUrl: './mfa-setup.component.html',
  styleUrls: ['./mfa-setup.component.scss']
})
export class MfaSetupComponent {
  @Input() id: string;
  @Input() qrCode: QrCode;
  @Output() onSetupComplete: EventEmitter<LoginRes> = new EventEmitter<LoginRes>();
  @Output() onDoneClicked = new EventEmitter();
  @Output() onTokenExpired = new EventEmitter();

  step: MFA_SETUP_STEPS = MFA_SETUP_STEPS.SETUP_INFO;
  MFA_SETUP_STEPS = MFA_SETUP_STEPS;

  setupMfa() {
    this.step = MFA_SETUP_STEPS.AUTH_APP_REGISTRATION;
  }

  confirmMfa(loginRes: LoginRes) {
    this.onSetupComplete.emit(loginRes);
    this.step = MFA_SETUP_STEPS.RECOVERY_CODES;
  }

  done() {
    this.onDoneClicked.emit();
  }

  tokenExpired() {
    this.onTokenExpired.emit();
  }
}
