<div class="page">
  <div class="page__content">
    <div class="content-title">{{"CREATE.ROLE.TITLE" | translate}}</div>

    <form class="content-form" [formGroup]="form">
      <input type="hidden" formControlName="role" />
      <div class="role-wrapper">
        <div *ngFor="let role of roles" class="role" [ngClass]="{'active': role.value === form.get('role').value }" (click)="toggleSelection(role)">
          <div class="role__label">{{role.label | translate}}</div>
        </div>
      </div>

      <div class="m-b-24 m-t-8" *ngIf="showOtherInputField" [@fadeInOutAnimation]>
        <!-- Other - user Role -->
        <input
          type="text"
          class="input"
          [@fadeInOutAnimation]
          autocomplete="off"
          formControlName="otherRole"
          id="create_input-business-type-other"
          [ngClass]="{ 'input--invalid': form.get('otherRole').touched && form.get('otherRole').invalid }"
          placeholder="{{ 'CREATE.ROLE.PLACEHOLDER_OTHER' | translate }}"
          maxlength="50"
        />

        <span *ngIf="form.get('otherRole').touched && form.get('otherRole').errors?.required" [@fadeInOutAnimation] class="form_error">
          {{ 'CREATE.ROLE.ERROR_OTHER' | translate }}
        </span>

        <span *ngIf="form.get('otherRole').touched  && form.get('otherRole').errors?.isSimpleStringValid " [@fadeInOutAnimation] class="form_error">
          {{ 'ERRORS.INVALID_SIMPLE_STRING' | translate }}
        </span>
        <!-- ./Other - user role -->
      </div>

      <!-- Next Button -->
      <button
        (click)="next(REGISTRATION_STEP_NAME.USER_ROLE, form.value)"
        class="button button--primary button--fluid m-t-12"
        type="button"
        [disabled]="!form.valid"
        id="step5-next"
      >
        <span>{{ 'CREATE.NEXT' | translate }}</span>
        <i class="fal fa-chevron-right"></i>
      </button>
      <!-- ./Next Button -->
    </form>
  </div>
  <div class="page__graphics">
    <div class="image-wrapper">
      <img src="/assets/images/registration/user-role.svg" />
    </div>
    <div class="text">{{"CREATE.ROLE.IMAGE_CAPTION" | translate}}</div>
  </div>
</div>
