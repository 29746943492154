import { VALID_TENANT } from '../constants/application.constants';

export class TenantService {
  static isSelfserviceTenant(tenant: string): boolean {
    return VALID_TENANT.includes(tenant.toUpperCase());
  }

  static isValidTenant = (tenant, countryList): boolean => {
    return !!countryList.find(item => item.urlkey === tenant);
  };
}
