<!-- step 1 -->
<lo-setup-info *ngIf="step === MFA_SETUP_STEPS.SETUP_INFO" (onSetupClick)="setupMfa()" id="setup-info-page"></lo-setup-info>
<!-- ./step 1 -->
<!-- step 2 -->
<lo-auth-app-registration
  id="setup-app-registration-page"
  [qrCode]="qrCode"
  *ngIf="step === MFA_SETUP_STEPS.AUTH_APP_REGISTRATION"
  (onConfirm)="confirmMfa($event)"
  (onTokenExpired)="tokenExpired()"
></lo-auth-app-registration>
<!-- ./step 2 -->
<!-- step 3 -->
<lo-recovery-code id="setup-recovery-code-page" *ngIf="step === MFA_SETUP_STEPS.RECOVERY_CODES" (onDoneClick)="done()"></lo-recovery-code>
<!-- ./step 3 -->
