<div class="page">
  <!-- main content -->
  <div class="page__content">
    <div class="content-title" *ngIf="!isUserInvited">{{ 'CREATE.PERSONAL_INFORMATION.TITLE' | translate }}</div>
    <div class="content-title" *ngIf="isUserInvited">{{ 'CREATE.PERSONAL_INFORMATION.TITLE_INVITED_USER' | translate }}</div>
    <form class="content-form" [formGroup]="form">
      <!-- input: email -->
      <div class="input-wrap input-wrap--processing m-b-12">
        <label class="input-label" for="create_input-email">{{ 'CREATE.PERSONAL_INFORMATION.EMAIL.LABEL' | translate }}</label>
        <span
          class="spinner"
          *ngIf="form.get('email').touched &&  !form.get('email').hasError('pattern') && !form.get('email').valid && !form.get('email').hasError('emailTaken') && !form.get('email').hasError('restrictedAddress')"
        >
          <i class="fad fa-spinner-third fa-spin"></i>
        </span>
        <input
          class="input"
          type="email"
          [@fadeInOutAnimation]
          autocomplete="off"
          [ngClass]="{
            'input--invalid':
              (form.get('email').touched  && form.get('email').invalid) || form.get('email').hasError('emailTaken') || form.get('email').hasError('restrictedAddress')
          }"
          formControlName="email"
          id="create_input-email"
        />

        <span
          [@fadeInOutAnimation]
          class="form_error"
          *ngIf="form.get('email').touched  && form.get('email').invalid && !form.get('email').hasError('emailTaken') && !form.get('email').hasError('restrictedAddress')"
        >
          {{ 'CREATE.PERSONAL_INFORMATION.EMAIL.ERROR' | translate }}
        </span>
        <span [@fadeInOutAnimation] class="form_error" id="email-taken-error" *ngIf="form.get('email').hasError('emailTaken')">
          {{ 'CREATE.PERSONAL_INFORMATION.EMAIL.ERROR_TAKEN' | translate }}
        </span>
        <span [@fadeInOutAnimation] class="form_error" id="email-restricted-error" *ngIf="form.get('email').hasError('restrictedAddress')">
          {{ 'CREATE.PERSONAL_INFORMATION.EMAIL.ERROR_RESTRICTED' | translate }}
        </span>
      </div>
      <!-- ./input: email -->

      <!-- Names -->
      <div class="single-row-field">
        <!-- first name -->
        <div class="input-wrap m-b-12">
          <label class="input-label" for="create_input-first-name">{{ 'CREATE.PERSONAL_INFORMATION.FIRSTNAME.LABEL' | translate }}</label>
          <input
            type="text"
            class="input"
            [@fadeInOutAnimation]
            formControlName="firstname"
            id="create_input-first-name"
            autocomplete="off"
            [ngClass]="{ 'input--invalid': form.get('firstname').touched && form.get('firstname').invalid }"
            (blur)="trimSpaces(form.get('firstname'))"
            maxlength="50"
          />
          <span *ngIf="form.get('firstname').touched && form.get('firstname').errors?.required" [@fadeInOutAnimation] class="form_error" id="firstname-missing-error">
            {{ 'CREATE.PERSONAL_INFORMATION.FIRSTNAME.ERROR' | translate }}
          </span>

          <span *ngIf="form.get('firstname').touched && form.get('firstname').errors?.isSimpleStringValid" [@fadeInOutAnimation] class="form_error">
            {{ 'ERRORS.INVALID_SIMPLE_STRING' | translate }}
          </span>
        </div>
        <!-- ./first name -->
        <!-- last name -->
        <div class="input-wrap m-b-12">
          <label class="input-label" for="create_input-last-name">{{ 'CREATE.PERSONAL_INFORMATION.LASTNAME.LABEL' | translate }}</label>
          <input
            type="text"
            class="input"
            [@fadeInOutAnimation]
            formControlName="lastname"
            id="create_input-last-name"
            autocomplete="off"
            [ngClass]="{ 'input--invalid': form.get('lastname').touched && form.get('lastname').invalid }"
            (blur)="trimSpaces(form.get('lastname'))"
            maxlength="50"
          />
          <span *ngIf="form.get('lastname').touched && form.get('lastname').errors?.required" [@fadeInOutAnimation] class="form_error" id="lastname-missing-error">
            {{ 'CREATE.PERSONAL_INFORMATION.LASTNAME.ERROR' | translate }}
          </span>

          <span *ngIf="form.get('lastname').touched && form.get('lastname').errors?.isSimpleStringValid" [@fadeInOutAnimation] class="form_error">
            {{ 'ERRORS.INVALID_SIMPLE_STRING' | translate }}
          </span>
        </div>
        <!-- ./last name -->
      </div>
      <!-- ./Names -->

      <!-- Next Button -->
      <button class="button button--primary button--fluid m-t-12" type="button" (click)="next('personalInformation', form.getRawValue())" [disabled]="!form.valid" id="step1-next">
        <span>{{ 'CREATE.NEXT' | translate }}</span>
        <span><i class="fal fa-chevron-right"></i></span>
      </button>
      <!-- ./Next Button -->
    </form>
  </div>
  <!-- ./main content -->

  <!-- right side graphics -->
  <lo-personal-information-graphics></lo-personal-information-graphics>
  <!-- ./right side graphics -->
</div>
