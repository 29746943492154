import { Component, HostBinding, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PAGE_VIEW_TYPES } from '../constants/application.constants';
import { fadeInOutAnimation, routeAnimation } from '../shared/animations';
import { PasswordForgotFormGroup } from '../shared/interfaces/forms';
import { setLanguage } from '../shared/language';
import { PlistaApiService } from '../shared/plista-api.service';
import { Store } from '../shared/store';
import { UtilsService } from '../shared/utils.service';
import { EMAIL_REGEXP } from '../shared/validation-pattern';

@Component({
  selector: 'lo-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss'],
  animations: [routeAnimation, fadeInOutAnimation]
})
export class PasswordForgotComponent implements OnInit, OnDestroy {
  @HostBinding('@routeAnimation') routeAnimation = true;
  @ViewChild('captchaRef') captchaRef;

  public form: FormGroup<PasswordForgotFormGroup>;
  public submitAttempt: boolean;
  public lang: string;
  public errorMessage: string;
  public processing: boolean;
  private resetPassword$: Subscription;

  public visiblePage: PAGE_VIEW_TYPES;
  public PAGE_VIEW_TYPES = PAGE_VIEW_TYPES;
  private isSendingRequest: boolean;

  constructor(private plistaApi: PlistaApiService, private translate: TranslateService, private titleService: Title, private fb: FormBuilder) {
    this.titleService.setTitle('Forgot Password | plista');
    this.lang = this.translate.currentLang;
  }

  ngOnInit(): void {
    this.createForm();
    UtilsService.hideAppLoader();
  }

  ngOnDestroy(): void {
    if (this.resetPassword$) {
      this.resetPassword$.unsubscribe();
    }
  }

  sendData(captchaResponse: string): void {
    if (this.email.valid && captchaResponse) {
      this.isSendingRequest = true;
      this.processing = true;
      this.resetPassword$ = this.plistaApi.resetPassword({ email: this.email.value, googleCaptcha: captchaResponse }).subscribe(
        () => {
          this.visiblePage = PAGE_VIEW_TYPES.SUCCESS_PAGE;
          this.processing = false;
          this.isSendingRequest = false;
        },
        () => {
          this.visiblePage = PAGE_VIEW_TYPES.ERROR_PAGE;
          this.processing = false;
          this.isSendingRequest = false;
          this.captchaRef.reset();
        }
      );
    }
  }

  onLanguageChange(lang): void {
    this.lang = lang;
    setLanguage(this.translate, this.lang);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick() {
    if (this.processing && !this.isSendingRequest) {
      this.processing = false;
    }
  }

  submit() {
    if (this.email.valid) {
      // when reCaptcha responds, sendData will be called programmaticly
      // function call is in the markup: (resolved)="sendData($event)"
      this.captchaRef.execute();
      return;
    }
    this.submitAttempt = true;
  }

  private createForm(): void {
    this.form = this.fb.group({
      email: [Store.email || '', [Validators.required, Validators.pattern(EMAIL_REGEXP)]]
    });
    this.visiblePage = PAGE_VIEW_TYPES.FORM_PAGE;
  }

  resend() {
    this.plistaApi.resetPassword({ email: Store.email || this.email.value }).subscribe(
      () => {
        this.visiblePage = PAGE_VIEW_TYPES.SUCCESS_PAGE;
      },
      () => {
        this.visiblePage = PAGE_VIEW_TYPES.ERROR_PAGE;
      }
    );
  }

  get email(): AbstractControl<string> {
    return this.form.get('email');
  }
}
