import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RequestStatus, StepService } from './../registration-steps/step.service';

@Injectable()
export class CanActivateError implements CanActivate {
  constructor(private router: Router, private stepService: StepService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.stepService.registrationData?.status === RequestStatus.FAILED) {
      return true;
    }

    return this.router.parseUrl(`/`);
  }
}
