import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { VALID_LANG_LIST } from '../constants/application.constants';

export function setLanguage(translate: TranslateService, lang = 'en'): Observable<any> {
  translate.setDefaultLang(lang);
  return translate.use(lang);
}

export function getValidLanguage(langInCookie: string, browserLang: string) {
  if (VALID_LANG_LIST.includes(langInCookie)) return langInCookie;
  if (VALID_LANG_LIST.includes(browserLang)) return browserLang;

  return 'en';
}
