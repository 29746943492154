import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PAGE_VIEW_TYPES } from '../constants/application.constants';
import { fadeInOutAnimation, routeAnimation } from '../shared/animations';
import { PlistaApiService } from '../shared/plista-api.service';
import { Store } from '../shared/store';
import { UtilsService } from '../shared/utils.service';
import { EMAIL_REGEXP } from '../shared/validation-pattern';
import { AccountVerificationFormGroup } from '../shared/interfaces/forms';

@Component({
  selector: 'lo-account-verification',
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.scss'],
  animations: [routeAnimation, fadeInOutAnimation]
})
export class AccountVerificationComponent implements OnInit, OnDestroy {
  @HostBinding('@routeAnimation') routeAnimation = true;

  public lang: string;
  public errorMessage: string;
  public language: string;
  public store;
  public processing: boolean;
  public resendProcessing: boolean;
  public visiblePage: PAGE_VIEW_TYPES;
  public PAGE_VIEW_TYPES = PAGE_VIEW_TYPES;
  public form: FormGroup<AccountVerificationFormGroup>;
  private resendVerificationEmail$: Subscription;
  public submitAttempt: boolean;

  constructor(private plistaApi: PlistaApiService, private translate: TranslateService, private fb: FormBuilder) {
    this.store = Store;
  }

  ngOnInit() {
    this.lang = this.translate.currentLang;
    this.createForm();
    UtilsService.hideAppLoader();
  }

  ngOnDestroy(): void {
    if (this.resendVerificationEmail$) {
      this.resendVerificationEmail$.unsubscribe();
    }
  }

  private createForm(): void {
    this.form = this.fb.group({
      email: [Store.email || '', [Validators.required, Validators.pattern(EMAIL_REGEXP)]]
    });
    this.visiblePage = PAGE_VIEW_TYPES.FORM_PAGE;
  }

  resend() {
    if (this.email.valid) {
      this.processing = true;
      this.submitAttempt = true;
      this.resendVerificationEmail$ = this.plistaApi.resendActivationMail({ email: this.email.value }).subscribe(
        () => {
          this.visiblePage = PAGE_VIEW_TYPES.SUCCESS_PAGE;
          this.processing = false;
        },
        () => {
          this.visiblePage = PAGE_VIEW_TYPES.ERROR_PAGE;
          this.processing = false;
        }
      );
    }
  }

  get email(): AbstractControl<string> {
    return this.form.get('email');
  }
}
