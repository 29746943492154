<div class="wrapper">
  <div class="search__wrapper" [ngClass]="{'disabled': disabled}">
    <input
      id="{{ id }}-search-input"
      class="search-input"
      [(ngModel)]="searchText"
      type="search"
      placeholder="{{ translations?.PLACEHOLDER }}"
      autocomplete="off"
      (focus)="onSearchFocus()"
      [disabled]="disabled"
    />
  </div>

  <div class="item__list" id="item-list" [ngStyle]="{'max-height': 39 * dropdownListSize + 'px'}" [ngClass]="{'is-open': showItemList}">
    <div class="selected-item" *ngIf="initialSelectedItem">
      {{ initialSelectedItem }}
      <span class="c-plista-grey">{{ translations?.SELECTED }}</span>
    </div>
    <div *ngFor="let item of itemList | filterPipe: searchText:filterByProperty; index as i">
      <div id="{{ id }}-{{ i }}" class="item" (click)="itemSelected(item, translations?.TYPES[item.transKey.toUpperCase()])">
        {{ translations?.TYPES[item.transKey.toUpperCase()] }}
      </div>
    </div>
    <div *ngIf="!(itemList | filterPipe: searchText:filterByProperty)?.length" class="no-result">{{ translations?.NO_RESULTS }}</div>
  </div>
</div>
