import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Unsubscriber } from '../../shared/unsubscriber';

@Component({
  selector: 'lo-setup-info',
  templateUrl: './setup-info.component.html',
  styleUrls: ['./setup-info.component.scss']
})
export class SetupInfoComponent extends Unsubscriber {
  @Input() id: string;
  @Output() onSetupClick = new EventEmitter();

  constructor() {
    super();
  }

  setupMfa() {
    this.onSetupClick.emit();
  }
}
