import { Component } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { setLanguage } from '../shared/language';

@Component({
  selector: 'lo-login-wrapper',
  templateUrl: './login-wrapper.component.html',
  styleUrls: ['./login-wrapper.component.scss']
})
export class LoginWrapperComponent {
  public lang: string;
  public currentRoute: string;
  public transKey: string;

  constructor(private translate: TranslateService, private router: Router) {
    this.lang = this.translate.currentLang;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.transKey = this.getTransKey(event.urlAfterRedirects);
      }
    });
  }

  getTransKey(url: string) {
    const validRouteNames = ['forgot-password', 'account-verification', 'advertiser-invitation', 'new-password'];
    const routeWithoutParams = url.split('?')[0];
    const routeName = routeWithoutParams.split('/')[1];
    return validRouteNames.find(item => routeName === item) ? routeName.replace('-', '_').toUpperCase() : 'LOGIN';
  }

  onLanguageChange(lang): void {
    this.lang = lang;
    setLanguage(this.translate, this.lang);
  }
}
