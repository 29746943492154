import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { sortByProperty } from '../../../app/shared/sorting';
import { TenantService } from '../../../app/shared/tenant.service';
import { StepService } from '../../shared/registration-steps/step.service';
import { INDIA_CITY_LIST } from '../create-user';
import { environment } from './../../../environments/environment';
import { CitySelectTransaltions, ItemSelect, SearchDropDownTranslations } from './../../search-dropdown/search-dropdown';
import { fadeInOutAnimation, routeAnimation } from './../../shared/animations';
import { Country, COUNTRY_LIST } from './../../shared/country-list';
import { BusinessLocationFormGroup } from '../../shared/interfaces/forms';
import { StepComponent } from './../../shared/registration-steps/step.directive';
import { BusinessLocation, UtilsService } from './../../shared/utils.service';
@Component({
  selector: 'lo-business-location',
  templateUrl: './business-location.component.html',
  styleUrls: ['./business-location.component.scss'],
  animations: [routeAnimation, fadeInOutAnimation]
})
export class BusinessLocationComponent extends StepComponent implements OnInit, OnDestroy {
  @Input() firstName: string;
  @ViewChild('businessLocation') businessLocationElement: ElementRef;

  public form: FormGroup<BusinessLocationFormGroup>;
  public countryDropdown = [];
  public isSelfserviceTenant: boolean;

  public businessLocations: BusinessLocation[];
  public selectedBusinessLocation: string;
  public initialSelectedValue: string;
  public initialSelectedTenantCode: string;
  public selectedCity: string;
  public initialSelectedCity: string;
  public filterByProperty: string;
  public selectedBusinessLocationName: string;
  public isUserInvited: boolean;
  public cityList: string[] = INDIA_CITY_LIST;
  public showIndiaCitySelectField: boolean;

  public translationsSearchDropdown: SearchDropDownTranslations;
  public translationsCitySelect: CitySelectTransaltions;

  private businessLocationFromParam: string;
  public isProduction = environment.production;

  constructor(private route: ActivatedRoute, private fb: FormBuilder, public stepService: StepService, private translate: TranslateService) {
    super(stepService);
    this.firstName = this.stepService.getStepDataByName('personalInformation')?.firstname;
  }

  ngOnInit(): void {
    if ((window as any).pixie) {
      (window as any).plista_pixie('Lead', 1585984);
    }

    this.subscriptions.push(this.translate.stream('BUSINESS_LOCATION').subscribe(trans => (this.translationsSearchDropdown = trans)));
    this.subscriptions.push(this.translate.stream('BUSINESS_LOCATION.CITY').subscribe(trans => (this.translationsCitySelect = trans)));

    this.subscriptions.push(
      this.translate.onLangChange.subscribe(() => {
        this.filterByProperty = 'name' + this.translate.currentLang.toUpperCase();
      })
    );

    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        if (params.exist === '0') {
          this.isUserInvited = true;
        }

        this.businessLocationFromParam = params?.tenant && TenantService.isValidTenant(params.tenant, UtilsService.getCountryList()) ? params.tenant : null;
      })
    );
    this.initForm(this.stepService.getStepDataByName('businessLocation'));
  }

  private initForm(stepData) {
    this.selectedBusinessLocation = stepData?.tenant || this.businessLocationFromParam || '';
    this.filterByProperty = 'name' + this.translate.currentLang.toUpperCase();
    this.initialSelectedCity = stepData?.city || '';

    if (this.selectedBusinessLocation) {
      const initialCountryInfo = COUNTRY_LIST.filter((country: Country): boolean => country.urlkey === this.selectedBusinessLocation);
      this.initialSelectedValue = this.translate.currentLang === 'en' ? initialCountryInfo[0].nameEN : initialCountryInfo[0].nameDE;
      this.selectedBusinessLocationName = initialCountryInfo[0].nameEN;
      this.stepService.emitBusinessLocation(initialCountryInfo[0].urlkey.toLowerCase());
      this.initialSelectedTenantCode = initialCountryInfo[0].urlkey;
    }

    this.isSelfserviceTenant = TenantService.isSelfserviceTenant(this.selectedBusinessLocation);
    this.businessLocations = UtilsService.getBusinessLocations();

    sortByProperty(this.businessLocations, this.filterByProperty);
    this.form = this.fb.group({
      tenant: [this.selectedBusinessLocation, Validators.required],
      city: ['']
    });

    this.showCitySelectField(this.initialSelectedTenantCode, this.initialSelectedCity);

    this.subscriptions.push(
      this.form.get('tenant').valueChanges.subscribe((newTenant: string) => {
        this.isSelfserviceTenant = TenantService.isSelfserviceTenant(newTenant);
      })
    );
  }

  onSelectItem(event: ItemSelect): void {
    this.form.get('tenant').setValue(event.transKey.toLowerCase());
    this.selectedBusinessLocationName = event.nameEN;
    this.stepService.emitBusinessLocation(event.transKey.toLowerCase());
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.showCitySelectField(event.transKey);
  }

  showCitySelectField(country: string, value: string = null): void {
    if (country && country.toUpperCase() === 'IN') {
      this.showIndiaCitySelectField = true;
      this.form.get('city').setValidators(Validators.required);
      this.form.get('city').setValue(value);
    } else {
      this.showIndiaCitySelectField = false;
      this.form.get('city').clearValidators();
      this.form.get('city').setValue(null);
    }
  }

  onCityChange(event: string): void {
    this.form.get('city').setValue(event.toLowerCase());
  }

  nextStep() {
    super.next('businessLocation', { ...this.form.value, name: this.selectedBusinessLocationName });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
