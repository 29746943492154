import { Component } from '@angular/core';
import { PlistaApiService } from '../../shared/plista-api.service';
import { Unsubscriber } from '../../shared/unsubscriber';
import { fadeInOutAnimation, routeAnimation } from './../../shared/animations';
import { RequestStatus, StepService } from './../../shared/registration-steps/step.service';
import { ToastService } from './../../toast/toast.service';

@Component({
  selector: 'lo-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
  animations: [routeAnimation, fadeInOutAnimation]
})
export class ConfirmComponent extends Unsubscriber {
  public userEmail: string;
  public processing: boolean;

  constructor(private stepService: StepService, private plistaApi: PlistaApiService, private toastService: ToastService) {
    super();
    this.userEmail = this.stepService.registrationData?.email;
    if ((window as any).pixie) {
      (window as any).plista_pixie('Lead', 1585985);
    }
  }

  resend() {
    this.processing = true;
    this.subscriptions.push(
      this.plistaApi.resendActivationMail({ email: this.userEmail }).subscribe(
        () => {
          this.toastService.open('CREATE.CONFIRM.SUCCESS', RequestStatus.SUCCESS);
        },
        () => {
          this.toastService.open('CREATE.CONFIRM.FAILED', RequestStatus.FAILED);
        },
        () => {
          this.processing = false;
        }
      )
    );
  }
}
