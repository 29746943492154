import { MarketingObjectiveComponent } from './../create-user/marketing-objective/marketing-objective.component';
import { Routes } from '@angular/router';
import { AccountVerificationComponent } from '../account-verification/account-verification.component';
import { AdvertiserInvitationComponent } from '../advertiser-invitation/advertiser-invitation.component';
import { BusinessLocationComponent } from '../create-user/business-location/business-location.component';
import { BusinessTypeComponent } from '../create-user/business-type/business-type.component';
import { PasswordComponent } from '../create-user/choose-password/password.component';
import { CompanyNameComponent } from '../create-user/company-name/company-name.component';
import { CreateUserComponent } from '../create-user/create-user.component';
import { RegistrationErrorComponent } from '../create-user/error/registration-error.component';
import { PersonalInformationComponent } from '../create-user/personal-information/personal-information.component';
import { RoleComponent } from '../create-user/role/your-role.component';
import { LoginWrapperComponent } from '../login-wrapper/login-wrapper.component';
import { LoginComponent } from '../login/login.component';
import { PasswordForgotComponent } from '../password-forgot/password-forgot.component';
import { PasswordResetComponent } from '../password-reset/password-reset.component';
import { CanActivateConfirm } from '../shared/guards/confirm.guard';
import { CanActivateError } from '../shared/guards/error.guard';
import { CanActivateStep } from '../shared/guards/step.guard';
import { ConfirmComponent } from './../create-user/confirm/confirm.component';

export const appRoutes: Routes = [
  {
    path: '',
    component: LoginWrapperComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LoginComponent
      },
      {
        path: ':tenant/forgot-password',
        redirectTo: 'forgot-password'
      },
      {
        path: ':tenant/account-verification',
        redirectTo: 'account-verification'
      },
      {
        path: ':tenant/new-password',
        redirectTo: 'new-password'
      },
      {
        path: ':tenant/advertiser-invitation',
        redirectTo: 'advertiser-invitation'
      },
      {
        path: 'forgot-password',
        component: PasswordForgotComponent
      },
      {
        path: 'account-verification',
        component: AccountVerificationComponent
      },
      {
        path: 'new-password',
        component: PasswordResetComponent
      },
      {
        path: 'advertiser-invitation',
        component: AdvertiserInvitationComponent
      }
    ]
  },
  {
    path: ':tenant/create',
    redirectTo: 'create'
  },
  {
    path: 'create',
    component: CreateUserComponent,
    children: [
      {
        path: '',
        redirectTo: 'personal-information',
        pathMatch: 'full'
      },
      {
        path: 'personal-information',
        component: PersonalInformationComponent,
        data: {
          animation: 1
        }
      },
      {
        path: 'business-location',
        component: BusinessLocationComponent,
        canActivate: [CanActivateStep],
        data: {
          animation: 2
        }
      },
      {
        path: 'business-type',
        component: BusinessTypeComponent,
        canActivate: [CanActivateStep],
        data: {
          animation: 3
        }
      },
      {
        path: 'company-name',
        component: CompanyNameComponent,
        canActivate: [CanActivateStep],
        data: {
          animation: 4
        }
      },
      {
        path: 'user-role',
        component: RoleComponent,
        canActivate: [CanActivateStep],
        data: {
          animation: 5
        }
      },
      {
        path: 'marketing-objective',
        component: MarketingObjectiveComponent,
        canActivate: [CanActivateStep],
        data: {
          animation: 6
        }
      },
      {
        path: 'password',
        component: PasswordComponent,
        canActivate: [CanActivateStep],
        data: {
          animation: 7
        }
      },
      {
        path: 'confirm',
        component: ConfirmComponent,
        canActivate: [CanActivateConfirm],
        data: {
          animation: 8
        }
      },
      {
        path: 'error',
        component: RegistrationErrorComponent,
        canActivate: [CanActivateError],
        data: {
          animation: 7
        }
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];
