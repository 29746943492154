import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MARKETING_OBJECTIVE_LIST } from '../create-user';
import { SearchDropDownTranslations } from './../../search-dropdown/search-dropdown';
import { fadeInOutAnimation } from './../../shared/animations';
import { MarketingObjectiveFormGroup } from '../../shared/interfaces/forms';
import { REGISTRATION_STEP_NAME } from './../../shared/registration-steps/step';
import { StepComponent } from './../../shared/registration-steps/step.directive';
import { StepService } from './../../shared/registration-steps/step.service';
import { MarketingObjective, MARKETING_OBJECTIVES } from './../create-user';

@Component({
  selector: 'lo-marketing-objective',
  templateUrl: './marketing-objective.component.html',
  styleUrls: ['./marketing-objective.component.scss'],
  animations: [fadeInOutAnimation]
})
export class MarketingObjectiveComponent extends StepComponent implements OnInit, OnDestroy {
  public form: FormGroup<MarketingObjectiveFormGroup>;
  public REGISTRATION_STEP_NAME = REGISTRATION_STEP_NAME;

  public marketingObjectiveList: MarketingObjective[] = MARKETING_OBJECTIVE_LIST;

  public marketingObjectives = MARKETING_OBJECTIVES;
  public initialSelectedValue: string;

  public translationsSelectField: SearchDropDownTranslations;

  constructor(private fb: FormBuilder, public stepService: StepService, private translate: TranslateService) {
    super(stepService);
  }

  ngOnInit(): void {
    this.subscriptions.push(this.translate.stream('CREATE.MARKETING_OBJECTIVE').subscribe(trans => (this.translationsSelectField = trans)));
    this.initForm(this.stepService.getStepDataByName(REGISTRATION_STEP_NAME.MARKETING_OBJECTIVE));
  }

  private initForm(stepData) {
    const selectedMarketingObjective = stepData?.marketingObjective || '';
    if (selectedMarketingObjective) {
      const selectedObjective = this.getMarketingObjectiveWithName(selectedMarketingObjective);
      this.initialSelectedValue = selectedObjective[0].transKey;
    }
    this.form = this.fb.group({
      marketingObjective: [selectedMarketingObjective, [Validators.required]]
    });
  }

  onMarketingObjectiveSelect(event): void {
    const selectedObjective = this.getMarketingObjectiveWithKey(event);
    this.form.get('marketingObjective').setValue(selectedObjective[0].nameEN);
  }

  getMarketingObjectiveWithKey(selectedObjective): MarketingObjective[] {
    return this.marketingObjectiveList.filter((objective: MarketingObjective) => selectedObjective === objective.transKey);
  }

  getMarketingObjectiveWithName(selectedObjective): MarketingObjective[] {
    return this.marketingObjectiveList.filter((objective: MarketingObjective) => selectedObjective === objective.nameEN);
  }

  isFormValid() {
    return this.form.valid;
  }
}
