<div class="page">
  <div class="page__content">
    <div class="content-title">{{ 'CREATE.BUSINESS_TYPE.TITLE' | translate }}</div>

    <form class="content-form" [formGroup]="form">
      <div class="input-wrap" [ngClass]="{'m-b-8': showOtherInputField}">
        <label class="input-label" for="select">{{ 'CREATE.BUSINESS_TYPE.LABEL' | translate }}</label>

        <lo-search-dropdown
          [id]="'business-type'"
          [translations]="translationsSearchDropdown"
          [initialSelectedValue]="initialSelectedValue"
          [itemList]="businessTypeList"
          [filterByProperty]="filterByProperty"
          [dropdownListSize]="4"
          (onSelectItem)="onSelectItem($event)"
        ></lo-search-dropdown>

        <span class="form_error" *ngIf="form.get('businessType').touched && form.get('businessType').invalid" [@fadeInOutAnimation]>
          {{ 'CREATE.BUSINESS_TYPE.ERROR' | translate }}
        </span>
      </div>

      <div class="input-wrap" *ngIf="showOtherInputField" [@fadeInOutAnimation]>
        <!-- Other - business type -->
        <input
          type="text"
          class="input"
          [@fadeInOutAnimation]
          autocomplete="off"
          formControlName="otherBusinessType"
          id="create_input-business-type-other"
          [ngClass]="{ 'input--invalid': form.get('otherBusinessType').touched && form.get('otherBusinessType').invalid }"
          placeholder="{{ 'CREATE.BUSINESS_TYPE.PLACEHOLDER_OTHER' | translate }}"
          maxlength="50"
        />

        <span *ngIf="form.get('otherBusinessType').touched && form.get('otherBusinessType').errors?.required" [@fadeInOutAnimation] class="form_error">
          {{ 'CREATE.BUSINESS_TYPE.ERROR_OTHER' | translate }}
        </span>

        <span *ngIf="form.get('otherBusinessType').touched  && form.get('otherBusinessType').errors?.isSimpleStringValid " [@fadeInOutAnimation] class="form_error">
          {{ 'ERRORS.INVALID_SIMPLE_STRING' | translate }}
        </span>
        <!-- ./Other - business type -->
      </div>

      <!-- Next Button -->
      <button
        (click)="next(REGISTRATION_STEP_NAME.BUSINESS_TYPE, form.value)"
        class="button button--primary button--fluid m-t-12"
        type="button"
        [disabled]="!form.valid"
        id="step3-next"
      >
        <span>{{ 'CREATE.NEXT' | translate }}</span>
        <i class="fal fa-chevron-right"></i>
      </button>
      <!-- ./Next Button -->
    </form>
  </div>
  <div class="page__graphics">
    <div class="image-wrapper">
      <img src="/assets/images/registration/business-type.svg" />
    </div>
    <div class="text">{{ 'CREATE.BUSINESS_TYPE.GRAPHICS' | translate }}</div>
  </div>
</div>
