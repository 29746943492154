export interface LoginRes {
  result: number;
  access_token?: string;
  token_type?: string;
  expires_at?: number;
  svg?: string;
  url?: string;
  secret_key?: string;
}

export enum MFA_SETUP_STEPS {
  SETUP_INFO,
  AUTH_APP_REGISTRATION,
  RECOVERY_CODES
}

export enum LoadingStatus {
  DEFAULT = 'DEFAULT',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

export enum MODE_OF_VERIFICATION {
  AUTH_APP,
  RECOVERY_CODE
}

export enum CODE_TYPE {
  AUTH_CODE,
  RECOVERY_CODE
}

export interface SecretKeyRes {
  data: {
    secret_key: string;
  };
}

export interface QrCode {
  svg: string;
  url: string;
  secret: string;
}

export enum CODE_LENGTH {
  AUTH_CODE = 6,
  RECOVERY_CODE = 21
}

export enum ERROR_CODES {
  TWO_FACTOR_SETUP_REQUIRED = 'TWO_FACTOR_SETUP_REQUIRED',
  UNKNOWN_API_SERVER_ERROR = 'UNKNOWN_API_SERVER_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
  DEACTIVATED_USER = 'DEACTIVATED_USER',
  WRONG_CHALLENGE_CODE = 'WRONG_CHALLENGE_CODE',
  EXPIRED_2FA_TOKEN = 'EXPIRED_2FA_TOKEN',
  NO_VALID_RECOVERY_CODE_OR_OTP_CODE = 'NO_VALID_RECOVERY_CODE_OR_OTP_CODE',
  INVALID_2FA_TOKEN = 'INVALID_2FA_TOKEN',
  X_PLISTA_2FA_TOKEN_HEADER_REQUIRED = 'X_PLISTA_2FA_TOKEN_HEADER_REQUIRED',
  CANNOT_DISABLE_2FA_FOR_INDIAN_USERS = 'CANNOT_DISABLE_2FA_FOR_INDIAN_USERS',
  NO_OTP_CODE_OR_RECOVERY_CODE_PROVIDED = 'NO_OTP_CODE_OR_RECOVERY_CODE_PROVIDED',
  VALIDATION_ERRORS = 'VALIDATION_ERRORS',
  WRONG_CONFIRMATION_CODE = 'WRONG_CONFIRMATION_CODE',
  TWO_FACTOR_NOT_ENABLED = 'TWO_FACTOR_NOT_ENABLED',
  TWO_FACTOR_NOT_CONFIRMED = 'TWO_FACTOR_NOT_CONFIRMED',
  TWO_FACTOR_ALREADY_CONFIRMED = 'TWO_FACTOR_ALREADY_CONFIRMED',
  MISSING_TWO_FACTOR_SECRET_OR_RECOVERY_CODES = 'MISSING_TWO_FACTOR_SECRET_OR_RECOVERY_CODES'
}
export interface ErrorRes {
  errorCode: ERROR_CODES;
}

export enum TOKEN_TYPE {
  CHALLENGE_TOKEN = '2fa_token',
  SETUP_TOKEN = 'setup_token',
  BEARER_TOKEN = 'Bearer'
}
