<div class="page">
  <div class="page__content">
    <div class="content-title">{{'CREATE.REGISTRATION_ERROR.TITLE'| translate}}</div>
    <div class="content-subtitle">{{'CREATE.REGISTRATION_ERROR.SUBTITLE'| translate}}</div>

    <div class="content-box">
      <div>
        {{'CREATE.REGISTRATION_ERROR.MESSAGE1' | translate}}
        <a (click)="showHelp()">{{'CREATE.REGISTRATION_ERROR.MESSAGE2' | translate}}</a>
        {{'CREATE.REGISTRATION_ERROR.MESSAGE3' | translate}}
      </div>
    </div>
  </div>
  <div class="page__graphics">
    <div class="image-wrapper">
      <img src="/assets/images/registration/error.svg" />
    </div>
  </div>
</div>
