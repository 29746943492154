import { Injectable } from '@angular/core';
import { RequestStatus } from '../shared/registration-steps/step.service';

export interface ToastParams {
  link?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  shown = false;
  responseMessage: string;
  status: RequestStatus;
  autoClose: boolean;
  params: ToastParams;

  open(responseMessage: string, status: RequestStatus, autoClose = true, params: ToastParams = {}): void {
    this.status = status;
    this.shown = true;
    this.responseMessage = responseMessage;
    this.autoClose = autoClose;
    this.params = params;

    if (autoClose) {
      setTimeout(() => (this.shown = false), 3000);
    }
  }

  close() {
    this.shown = false;
  }

  clear() {
    this.shown = false;
    this.responseMessage = null;
    this.status = null;
    this.autoClose = true;
    this.params = {};
  }
}
