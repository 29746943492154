import { animate, state, style, transition, trigger } from '@angular/animations';
/* core */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { fadeInOutAnimation } from '../shared/animations';
import { HelpModalFormGroup } from '../shared/interfaces/forms';
import { Store } from '../shared/store';
import { isSimpleStringValid } from '../shared/validation-methods';
/* custom */
import { HelpModalService } from './helpmodal.service';

@Component({
  selector: 'lo-helpmodal',
  templateUrl: './helpmodal.component.html',
  styleUrls: ['./helpmodal.component.scss'],
  animations: [
    fadeInOutAnimation,
    trigger('easeInOut', [
      state(
        'hide',
        style({
          right: '-{{maxWidth}}',
          visibility: 'hidden'
        }),
        {
          params: {
            maxWidth: '480px'
          }
        }
      ),
      state(
        'show',
        style({
          right: '0px',
          visibility: 'visible'
        })
      ),
      transition('show => hide', animate('300ms ease-out')),
      transition('hide => show', animate('300ms ease-in'))
    ]),
    trigger('easeInOutBtn', [
      state(
        'hide',
        style({
          right: '0px',
          background: '#767676',
          color: '#FFFFFF'
        })
      ),
      state(
        'show',
        style({
          right: '{{maxWidth}}',
          background: '#FFFFFF',
          color: '#242424'
        }),
        {
          params: {
            maxWidth: '480px'
          }
        }
      ),
      transition('show => hide', animate('300ms ease-out')),
      transition('hide => show', animate('300ms ease-in'))
    ])
  ]
})
export class HelpModalComponent implements OnInit, OnDestroy {
  public isFormSubmitted = false;
  public isFormErrored = false;
  public isFormSubmissionLoading = false;
  public area = 'publishers'; // default as 'publishers'
  public isModalActive$: Subscription;
  public form: FormGroup<HelpModalFormGroup>;
  public showIcon: boolean;
  public showModal: boolean;

  constructor(private fb: FormBuilder, private helpModalService: HelpModalService) {}

  ngOnInit() {
    this.showModal = false;
    this.initForm();

    this.isModalActive$ = this.helpModalService.isModalActive.subscribe(data => {
      /**
       * Handle toggling the modal
       */
      if (data['area']) {
        this.area = data['area'];
      }

      /**
       * Handle other events such as 'submitted' & 'error'
       */
      if (data === true) {
        if (Store.email && Store.username) {
          this.form.get('name').setValue(Store.username);
          this.form.get('name').markAsTouched();
          this.form.get('email').setValue(Store.email);
          this.form.get('email').markAsTouched();
        }
        // Create form in cases helpmodal is called from the outside
        this.initForm();
        this.openModal();
      } else if (data['status'] === 'submitted') {
        this.isFormSubmitted = true;
        this.isFormErrored = false;
        this.isFormSubmissionLoading = false;
      } else if (data['status'] === 'error') {
        this.isFormSubmitted = false;
        this.isFormErrored = true;
        this.isFormSubmissionLoading = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.isModalActive$) {
      this.isModalActive$.unsubscribe();
    }
  }

  openModal() {
    this.showModal = !this.showModal;
    if (this.showModal) {
      this.showIcon = true;
    } else {
      this.showIcon = false;
      this.isFormSubmitted = false;
      this.isFormSubmissionLoading = false;
      this.isFormErrored = false;
    }
  }

  public initForm(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required, isSimpleStringValid]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required]]
    });
  }

  public resetForm(): void {
    this.form.reset();
  }

  public sendMessage(): void {
    const metaData = {
      OS: this.helpModalService.getOS(),
      Browser: this.helpModalService.getBrowser(),
      CookiesEnabled: this.helpModalService.getCookieEnabled(),
      Screen: this.helpModalService.getScreenInfo()
    };

    const domain = this.helpModalService.getDomain();

    const modalData = {
      metaData,
      name: this.form.get('name').value,
      email: this.form.get('email').value,
      message: this.form.get('message').value,
      domain,
      url: window.location.href
    };

    this.helpModalService.sendData(modalData);
    this.isFormSubmissionLoading = true;
    this.form.reset(); // clear form fields after successfull submition
  }
}
