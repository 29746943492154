import { Injectable } from '@angular/core';
import { PlistaCookieService } from './cookie.service';
import { isAdvertiser, isCampaignManager, isPublisher } from './groups-roles';
import { NavigationHelper } from './navigation';
import { PlistaApiService } from './plista-api.service';
import { Store } from './store';
import { TenantService } from './tenant.service';

export interface NavigationParams {
  isNewUser: boolean;
  id: number;
  roles: string[];
  masterTenant: string;
  advertiserId?: number;
}

@Injectable()
export class NavigationService {
  constructor(private plistaApi: PlistaApiService, private cookieService: PlistaCookieService) {}

  navigateToApp(navigationParams: NavigationParams) {
    this.cookieService.setCookies('tenant', navigationParams.masterTenant);
    if (Store.redirectUrl) {
      window.location.assign(Store.redirectUrl);
    } else if (isPublisher(navigationParams.roles)) {
      window.location.assign(NavigationHelper.getPlanetUrl(navigationParams.masterTenant));
    } else if (isCampaignManager(navigationParams.roles)) {
      window.location.assign(NavigationHelper.statisticsUrl(navigationParams.masterTenant));
    } else if (isAdvertiser(navigationParams.roles)) {
      this.checkForAdvertisers(navigationParams);
    } else {
      window.location.assign(NavigationHelper.getPlanetUrl(navigationParams.masterTenant));
    }
  }

  private checkForAdvertisers(navigationParams: NavigationParams) {
    const tenant = TenantService.isSelfserviceTenant(navigationParams.masterTenant) ? navigationParams.masterTenant : 'de';
    this.plistaApi.getAdvertiserIds(navigationParams.masterTenant, navigationParams.id).subscribe(
      (ids: any) => {
        const hasAdvertisers = !!(ids && ids.length);

        if (hasAdvertisers) {
          this.checkForCampaigns({ ...navigationParams, advertiserId: ids[0].advertiserId });
        } else {
          window.location.assign(NavigationHelper.bookingsUrl(navigationParams.masterTenant));
        }
      },
      () => {
        window.location.assign(NavigationHelper.bookingsUrl(tenant, navigationParams.isNewUser));
      }
    );
  }

  private checkForCampaigns(navigationParams: NavigationParams) {
    this.plistaApi.getCampaignList(navigationParams.masterTenant, navigationParams.advertiserId).subscribe(
      campaigns => {
        if (campaigns && campaigns.length) {
          window.location.assign(NavigationHelper.statisticsUrl(navigationParams.masterTenant));
        } else {
          window.location.assign(NavigationHelper.bookingsUrl(navigationParams.masterTenant, navigationParams.isNewUser));
        }
      },
      () => {
        window.location.assign(NavigationHelper.statisticsUrl(navigationParams.masterTenant));
      }
    );
  }
}
