import { Directive } from '@angular/core';
import { STORAGE_KEY } from '../constants';
import { StorageHelper } from '../helper/storage.helper';
import { Unsubscriber } from '../unsubscriber';
import { StepService } from './step.service';

@Directive()
export class StepComponent extends Unsubscriber {
  constructor(protected stepService: StepService) {
    super();
  }

  next(stepName?: string, formValue?: any) {
    if (stepName && formValue) {
      this.stepService.save(stepName, formValue);
      StorageHelper.save(STORAGE_KEY, this.stepService.stepData);
    }

    this.stepService.next();
  }
}
