import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { environment } from '../../environments/environment';
export class SentryHelper {
  static init(): void {
    const options: Sentry.BrowserOptions = {
      environment: environment.name,
      dsn: environment.sentryKey,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: [environment.apiUrl],
          routingInstrumentation: Sentry.routingInstrumentation
        })
      ],
      tracesSampleRate: 0.1,
      normalizeDepth: 10
    };

    if (environment.production) {
      options.release = environment.version;
    }

    Sentry.init(options);
  }

  static captureException(message: string, error: any) {
    Sentry.setContext('message', { message });
    Sentry.captureException(error);
  }
}
