<div class="page__graphics has-animation">
  <!-- block one  -->
  <div class="item">
    <div class="item__image-container">
      <div *ngIf="currentAnimationIndex === 0" [@fadeInOutAnimationSlow] class="item__image item__image--one"></div>
      <div class="item__image item__image--one-gray"></div>
    </div>

    <div class="item__title" [ngClass]="{'active': currentAnimationIndex === 0}">{{"CREATE.PERSONAL_INFORMATION.GRAPHICS.STEP_1" | translate}}</div>
  </div>
  <!-- ./block one -->

  <div class="separator"></div>

  <!-- block two -->
  <div class="item">
    <div class="item__image-container">
      <div *ngIf="currentAnimationIndex === 1" [@fadeInOutAnimationSlow] class="item__image item__image--two"></div>
      <div class="item__image item__image--two-gray"></div>
    </div>
    <div class="item__title" [ngClass]="{'active': currentAnimationIndex === 1}">{{"CREATE.PERSONAL_INFORMATION.GRAPHICS.STEP_2" | translate}}</div>
  </div>
  <!-- ./block two -->

  <div class="separator"></div>

  <!-- block three -->
  <div class="item">
    <div class="item__image-container">
      <div *ngIf="currentAnimationIndex === 2" [@fadeInOutAnimationSlow] class="item__image item__image--three"></div>
      <div class="item__image item__image--three-gray" [ngClass]="{'active': currentAnimationIndex === 2}"></div>
    </div>

    <div class="item__title" [ngClass]="{'active': currentAnimationIndex === 2}">{{"CREATE.PERSONAL_INFORMATION.GRAPHICS.STEP_3" | translate}}</div>
  </div>
  <!-- ./block three -->
</div>
