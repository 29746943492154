import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ChoosePasswordFormGroup } from '../../shared/interfaces/forms';
import { STORAGE_KEY, UPDATE_GTM_LAYER } from '../../shared/constants';
import { REGISTRATION_STEP_NAME } from '../../shared/registration-steps/step';
import { Unsubscriber } from '../../shared/unsubscriber';
import { minLength } from '../../shared/validation-methods';
import { getLegalLinks, LegalLink } from './../../app.links';
import { DEFAULT_TENANT, INPUT_TYPE } from './../../constants/application.constants';
import { fadeInOutAnimation, routeAnimation } from './../../shared/animations';
import { PlistaCookieService } from './../../shared/cookie.service';
import { GoogleTagManagerHelper } from './../../shared/helper/google-tag-manager.helper';
import { StorageHelper } from './../../shared/helper/storage.helper';
import { PlistaApiService } from './../../shared/plista-api.service';
import { StepService } from './../../shared/registration-steps/step.service';
import { SentryHelper } from './../../shared/sentry';
import { TenantService } from './../../shared/tenant.service';
@Component({
  selector: 'lo-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  animations: [routeAnimation, fadeInOutAnimation]
})
export class PasswordComponent extends Unsubscriber implements OnInit, OnDestroy {
  @ViewChild('captchaRef') captchaRef;

  public form: FormGroup<ChoosePasswordFormGroup>;
  public passwordFieldFocused: boolean;
  public showPassword: boolean;
  public legalLinks: LegalLink;
  public lang: string;
  public passwordInputType: INPUT_TYPE;
  public processing: boolean;
  private code: string;
  private ref: string;
  private routeQueryParams$: Subscription;
  private businessLocation: string;
  public isUserInvited: boolean;
  private invitedTo;
  private isRegistering: boolean;

  constructor(
    private fb: FormBuilder,
    public stepService: StepService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private plistaApi: PlistaApiService,
    private plistaCookieService: PlistaCookieService
  ) {
    super();
    this.businessLocation = this.stepService.getStepDataByName(REGISTRATION_STEP_NAME.BUSINESS_LOCATION)?.tenant;
    this.legalLinks = getLegalLinks(this.translateService.currentLang, this.businessLocation);
    this.lang = translateService.currentLang;

    this.subscriptions.push(
      translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.lang = event.lang;
        this.legalLinks = getLegalLinks(event.lang, this.businessLocation);
      })
    );

    this.passwordInputType = INPUT_TYPE.PASSWORD;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick() {
    if (this.processing && !this.isRegistering) {
      this.processing = false;
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        this.code = params?.code;
        this.ref = params?.ref;
        if (params.exist === '0') {
          this.isUserInvited = true;
        }

        if (params.invitedTo) {
          this.invitedTo = params.invitedTo;
        }
      })
    );
    this.initForm();
  }

  showPasswordClicked(val: boolean): void {
    this.showPassword = val;
    this.passwordInputType = val ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD;
  }
  private initForm() {
    this.form = this.fb.group({
      password: ['', [Validators.required, minLength]]
    });
  }

  register(captcha: string) {
    if (captcha) {
      this.isRegistering = true;
      const password = this.form.get('password').value;
      const stepData = this.stepService.stepData;
      const isSelfserviceTenant = TenantService.isSelfserviceTenant(stepData.businessLocation.tenant);
      const userData = this.prepareUserData({ ...stepData, password });
      const extraData = {
        type: 'advertiser',
        clientType: 'Self Service',
        termsAndConditionsAccepted: true,
        googleCaptcha: captcha,
        preferredLanguage: this.lang,
        code: this.code || '',
        referrer: this.ref,
        hubspotutk: this.plistaCookieService.getCookies('hubspotutk') || '',
        invitedUser: this.isUserInvited
      };

      if (this.invitedTo) {
        extraData['invitedTo'] = this.invitedTo;
      }

      const apiTenant = isSelfserviceTenant ? stepData.businessLocation.tenant : DEFAULT_TENANT;

      this.plistaApi.createUser({ ...userData, ...extraData }, apiTenant).subscribe(
        () => {
          this.stepService.onSuccessfulRegistration();
          StorageHelper.clear(STORAGE_KEY);
          GoogleTagManagerHelper.pushEvent({
            event: UPDATE_GTM_LAYER,
            ac_market_selected: stepData.businessLocation.tenant,
            ac_lang: this.lang
          });
        },
        error => {
          this.stepService.onFailedRegistration();
          StorageHelper.clear(STORAGE_KEY);
          this.captchaRef.reset();
          SentryHelper.captureException('Registration Failed', error);
        },
        () => {
          this.processing = false;
          this.isRegistering = false;
        }
      );
    } else {
      this.isRegistering = false;
      this.processing = false;
    }
  }

  executeCaptcha() {
    this.processing = true;
    // when reCaptcha responds, create will be called programmaticly
    // function call is in the markup: (resolved)="create($event)"
    this.captchaRef.execute();
  }

  prepareUserData(formData) {
    return {
      firstname: formData.personalInformation.firstname,
      lastname: formData.personalInformation.lastname,
      email: formData.personalInformation.email.trim(),
      businessType: formData.businessType.businessType === 'Other' ? formData.businessType.otherBusinessType : formData.businessType.businessType,
      businessLocation: formData.businessLocation.name,
      city: formData.businessLocation.city,
      companyName: formData.companyName.companyName,
      jobTitle: formData.userRole.role === 'Other' ? formData.userRole.otherRole : formData.userRole.role,
      goal: formData.marketingObjective.marketingObjective, // in BE it is called GOAL
      password: formData.password
    };
  }

  ngOnDestroy(): void {
    if (this.routeQueryParams$) {
      this.routeQueryParams$.unsubscribe();
    }
  }
}
