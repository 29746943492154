import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { REGISTRATION_STEP_NAME } from '../../shared/registration-steps/step';
import { fadeInOutAnimation, routeAnimation } from './../../shared/animations';
import { CompanyNameFormGroup } from '../../shared/interfaces/forms';
import { StepComponent } from './../../shared/registration-steps/step.directive';
import { StepService } from './../../shared/registration-steps/step.service';
import { isStringValid } from './../../shared/validation-methods';
@Component({
  selector: 'lo-company-name',
  templateUrl: './company-name.component.html',
  styleUrls: ['./company-name.component.scss'],
  animations: [routeAnimation, fadeInOutAnimation]
})
export class CompanyNameComponent extends StepComponent implements OnInit {
  public form: FormGroup<CompanyNameFormGroup>;
  public REGISTRATION_STEP_NAME = REGISTRATION_STEP_NAME;
  public isUserInvited: boolean;
  public advertiserFromParam: string;

  constructor(private route: ActivatedRoute, private fb: FormBuilder, public stepService: StepService) {
    super(stepService);
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.queryParams.subscribe(param => {
        if (param.exist === '0' && param.advertiser) {
          this.isUserInvited = true;
          this.advertiserFromParam = param.advertiser;
        }
      })
    );
    this.initForm(this.stepService.getStepDataByName(REGISTRATION_STEP_NAME.COMPANY_NAME));
  }

  private initForm(stepData) {
    this.form = this.fb.group({
      companyName: [stepData?.companyName || this.advertiserFromParam || '', [Validators.required, isStringValid]]
    });

    if (this.isUserInvited) {
      this.form.controls.companyName.disable();
    }
  }

  isFormValid() {
    return this.form.valid || this.isUserInvited;
  }
}
