import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { DEFAULT_TENANT } from './../constants/application.constants';
import { CODE_TYPE, LoginRes } from './interfaces/common';

export interface INewUser {
  firstname: string;
  lastname: string;
  email: string;
  businessType: string;
  businessLocation: string;
  companyName: string;
  jobTitle: string;
  password: string;
  type: string;
  clientType: string;
  termsAndConditionsAccepted: boolean;
  googleCaptcha: string;
  preferredLanguage: string;
  code: string;
  hubspotutk: string;
}

@Injectable()
export class PlistaApiService {
  private _temporaryToken: string;
  private _email: string;

  transactionId: string;
  cookies;

  constructor(private http: HttpClient) {}

  resetPassword(body: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${DEFAULT_TENANT}/authorization/passwordForgot`, body);
  }

  set temporaryToken(value: string) {
    this._temporaryToken = value;
  }

  get temporaryToken(): string {
    return this._temporaryToken;
  }

  set email(value: string) {
    this._email = value;
  }

  get email(): string {
    return this._email;
  }

  resetTemporaryToken() {
    this.temporaryToken = null;
    this.email = null;
  }

  getTemporaryAuthHeaders() {
    const headers = {
      headers: new HttpHeaders({
        'X-PLISTA-USER-EMAIL': this.email,
        'X-PlISTA-2FA-TOKEN': this.temporaryToken
      })
    };
    return headers;
  }

  getUser(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/${DEFAULT_TENANT}/users/me`);
  }

  /** Login the user */
  loginUser(body: { email: string; password: string }): Observable<LoginRes> {
    return this.http.post<{ data: LoginRes }>(`${environment.apiUrl}/${DEFAULT_TENANT}/auth/login`, body).pipe(pluck('data'));
  }

  createUser(body: INewUser, tenant): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${tenant}/users/createUser`, body);
  }

  isPasswordResetValid(body): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${DEFAULT_TENANT}/users/selfservice/validate_hash`, body).pipe(pluck('data'));
  }

  changeUserPassword(body) {
    return this.http.post(`${environment.apiUrl}/${DEFAULT_TENANT}/users/selfservice/update_password`, body);
  }

  public isEmailTaken(email: string): Observable<any> {
    this.transactionId = (+new Date() / 1000).toString();
    return this.getUniqueVector(email);
  }

  getEncryptedEmail(encryptionStrings): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/${DEFAULT_TENANT}/users/userExist?emailkey=${encryptionStrings.key}&vector=${encryptionStrings.vector}&transactionid=${this.transactionId}`
    );
  }

  getUniqueVector(emailToCheck): Observable<any> {
    return this.http.get(`${environment.apiUrl}/${DEFAULT_TENANT}/crypt/encrypt?transactionid=${this.transactionId}&key=${emailToCheck}`);
  }

  resendActivationMail(body: { email: string }): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${DEFAULT_TENANT}/users/selfservice/resend`, body);
  }

  getAdvertiserIds(tenant, userId) {
    return this.http.get(`${environment.apiUrl}/${tenant}/advertiser/mapping/advertiser-tenant/${userId}`);
  }

  // TODO: check if tenant has any relation with email address where message is sent
  sendMessage(message) {
    return this.http.post(`${environment.apiUrl}/${DEFAULT_TENANT}/general/helpdesk`, message);
  }

  getCampaignList(masterTenant: string, advertiserId: number): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/${masterTenant}/advertiser/multi-line-item?advertiser_id=${advertiserId}`);
  }

  confirm2Fa(payload: { code: string }): Observable<LoginRes> {
    return this.http.post<{ data: LoginRes }>(`${environment.apiUrl}/${DEFAULT_TENANT}/auth/mfa/confirm/setup`, payload, this.getTemporaryAuthHeaders()).pipe(pluck('data'));
  }

  challenge2Fa(code: string, codeType: CODE_TYPE): Observable<LoginRes> {
    let payload;
    if (codeType === CODE_TYPE.AUTH_CODE) {
      payload = { code };
    } else {
      payload = { recovery_code: code };
    }

    return this.http.post<{ data: LoginRes }>(`${environment.apiUrl}/${DEFAULT_TENANT}/auth/mfa/challenge`, payload, this.getTemporaryAuthHeaders()).pipe(pluck('data'));
  }
}
