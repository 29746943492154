import { NavigationHelper } from './../../shared/navigation';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Unsubscriber } from './../../shared/unsubscriber';

@Component({
  selector: 'lo-recovery-code',
  templateUrl: './recovery-code.component.html',
  styleUrls: ['./recovery-code.component.scss']
})
export class RecoveryCodeComponent extends Unsubscriber {
  @Input() id: string;
  @Output() onDoneClick = new EventEmitter();

  fakeLoading: boolean;

  private downloadUrl = NavigationHelper.getRecoveryCodeDownloadUrl();

  constructor() {
    super();
  }

  downloadRecoveryCodes() {
    window.open(this.downloadUrl, '_blank');
  }

  done() {
    this.fakeLoading = true;
    this.onDoneClick.emit();
  }
}
