import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { setLanguage } from '../../shared/language';
@Component({
  selector: 'lo-create-header',
  templateUrl: './create-header.component.html',
  styleUrls: ['./create-header.component.scss']
})
export class CreateHeaderComponent implements OnInit {
  @Input() progress: number;
  @Input() translations;

  lang: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.lang = this.translateService.currentLang;
  }

  onLanguageChange(lang: string): void {
    this.lang = lang;
    setLanguage(this.translateService, this.lang);
  }
}
