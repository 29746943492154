export interface RegistrationStep {
  routeName: string;
  stepName: string;
  fields?: string[];
  order: number;
}

export enum REGISTRATION_STEP_ROUTE_NAME {
  PERSONAL_INFORMATION = 'personal-information',
  BUSINESS_LOCATION = 'business-location',
  BUSINESS_TYPE = 'business-type',
  COMPANY_NAME = 'company-name',
  USER_ROLE = 'user-role',
  MARKETING_OBJECTIVE = 'marketing-objective',
  PASSWORD = 'password',
  CONFIRM = 'confirm',
  ERROR = 'error'
}

export enum REGISTRATION_STEP_NAME {
  PERSONAL_INFORMATION = 'personalInformation',
  BUSINESS_LOCATION = 'businessLocation',
  BUSINESS_TYPE = 'businessType',
  COMPANY_NAME = 'companyName',
  USER_ROLE = 'userRole',
  MARKETING_OBJECTIVE = 'marketingObjective',
  PASSWORD = 'password',
  CONFIRM = 'confirm',
  ERROR = 'error'
}

export const REGISTRATION_STEPS: RegistrationStep[] = [
  {
    routeName: REGISTRATION_STEP_ROUTE_NAME.PERSONAL_INFORMATION,
    stepName: REGISTRATION_STEP_NAME.PERSONAL_INFORMATION,
    fields: ['firstname', 'lastname', 'email'],
    order: 0
  },
  {
    routeName: REGISTRATION_STEP_ROUTE_NAME.BUSINESS_LOCATION,
    stepName: REGISTRATION_STEP_NAME.BUSINESS_LOCATION,
    fields: ['tenant'],
    order: 1
  },
  {
    routeName: REGISTRATION_STEP_ROUTE_NAME.BUSINESS_TYPE,
    stepName: REGISTRATION_STEP_NAME.BUSINESS_TYPE,
    fields: ['businessType'],
    order: 2
  },
  {
    routeName: REGISTRATION_STEP_ROUTE_NAME.COMPANY_NAME,
    stepName: REGISTRATION_STEP_NAME.COMPANY_NAME,
    fields: ['companyName'],
    order: 3
  },
  {
    routeName: REGISTRATION_STEP_ROUTE_NAME.USER_ROLE,
    stepName: REGISTRATION_STEP_NAME.USER_ROLE,
    fields: ['role'],
    order: 4
  },
  {
    routeName: REGISTRATION_STEP_ROUTE_NAME.MARKETING_OBJECTIVE,
    stepName: REGISTRATION_STEP_NAME.MARKETING_OBJECTIVE,
    fields: ['marketingObjective'],
    order: 5
  },
  {
    routeName: REGISTRATION_STEP_ROUTE_NAME.PASSWORD,
    stepName: REGISTRATION_STEP_NAME.PASSWORD,
    fields: [],
    order: 6
  },
  {
    routeName: REGISTRATION_STEP_ROUTE_NAME.CONFIRM,
    stepName: REGISTRATION_STEP_NAME.CONFIRM,
    order: 7
  }
];

export const getStepOrderByRouteName = (routeName: REGISTRATION_STEP_ROUTE_NAME): number => {
  const step: RegistrationStep = REGISTRATION_STEPS.find(step => step.routeName === routeName);
  return step ? step.order : null;
};
