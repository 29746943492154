import { HelpModalService } from './../../helpmodal/helpmodal.service';
import { Component } from '@angular/core';

@Component({
  selector: 'lo-registration-error',
  templateUrl: './registration-error.component.html',
  styleUrls: ['./registration-error.component.scss']
})
export class RegistrationErrorComponent {
  constructor(private helpModalService: HelpModalService) {}

  showHelp() {
    this.helpModalService.toggleHelpModalForm(true);
  }
}
