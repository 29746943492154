import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { debounceTime } from 'rxjs/operators';
import { fadeInOutAnimation } from '../../shared/animations';
import { CODE_LENGTH, LoadingStatus } from '../../shared/interfaces/common';
import { PlistaApiService } from '../../shared/plista-api.service';
import { ERROR_CODES, LoginRes, QrCode } from './../../shared/interfaces/common';
import { AuthAppRegistrationFormGroup } from '../../shared/interfaces/forms';
import { SentryHelper } from './../../shared/sentry';
import { AUTH_CODE } from './../../shared/validation-pattern';

@Component({
  selector: 'lo-auth-app-registration',
  templateUrl: './auth-app-registration.component.html',
  styleUrls: ['./auth-app-registration.component.scss'],
  animations: [fadeInOutAnimation]
})
export class AuthAppRegistrationComponent implements AfterViewInit {
  @Input() id: string;

  @Input()
  set qrCode(code: QrCode) {
    this.qrCodeSvg = this.domSanitizer.bypassSecurityTrustHtml(code.svg);
    this.qrCodeString = code.secret;
  }

  @Output() onConfirm: EventEmitter<LoginRes> = new EventEmitter<LoginRes>();
  @Output() onTokenExpired = new EventEmitter();
  @ViewChild('setUpCodeField') setUpCodeFieldElement: ElementRef;

  form: FormGroup<AuthAppRegistrationFormGroup>;
  submitStatus: LoadingStatus;
  LoadingStatus = LoadingStatus;
  qrCodeSvg: SafeHtml;
  qrCodeString: string;
  errorMessageKey: string;
  codeLength: CODE_LENGTH = CODE_LENGTH.AUTH_CODE;

  constructor(private api: PlistaApiService, private fb: FormBuilder, private domSanitizer: DomSanitizer) {
    this.initForm();
  }
  ngAfterViewInit(): void {
    this.setUpCodeFieldElement.nativeElement.focus();
  }

  private initForm() {
    this.form = this.fb.group({
      code: ['', [Validators.required, Validators.pattern(AUTH_CODE)]]
    });

    this.form
      .get('code')
      .valueChanges.pipe(debounceTime(100))
      .subscribe(codeValue => {
        this.submitStatus = LoadingStatus.DEFAULT;

        /**auto submit on valid code */
        if (codeValue.length === this.codeLength && this.form.valid) {
          this.confirmCode();
        }
      });
  }

  confirmCode() {
    this.submitStatus = LoadingStatus.LOADING;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.api.confirm2Fa({ code: this.form.get('code').value }).subscribe(
      (response: LoginRes) => {
        this.submitStatus = LoadingStatus.SUCCESS;
        this.onConfirm.emit(response);
      },
      error => {
        const errorCode = error?.['error']?.['errorCode'];

        if ((errorCode as ERROR_CODES) === ERROR_CODES.EXPIRED_2FA_TOKEN) {
          this.onTokenExpired.emit();
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (Object.values(ERROR_CODES).includes(errorCode)) {
          this.errorMessageKey = `MFA_ERRORS.${errorCode}`;
        } else {
          this.errorMessageKey = `MFA_ERRORS.UNKNOWN`;
          SentryHelper.captureException('2FA Setup failed: code verification failed', error);
        }

        this.submitStatus = LoadingStatus.FAILED;
      }
    );
  }
}
