<div class="page">
  <div class="page__content">
    <div class="content-subtitle">{{"CREATE.BUSINESS_LOCATION.SUB_TITLE" | translate: {firstName: firstName} }}</div>
    <div class="content-title">{{"CREATE.BUSINESS_LOCATION.TITLE" | translate}}</div>
    <form class="content-form" [formGroup]="form">
      <div class="input-wrap" [ngClass]="{'m-b-8': showIndiaCitySelectField}">
        <label class="input-label" for="select">{{ 'CREATE.BUSINESS_LOCATION.MARKET.LABEL' | translate }}</label>

        <lo-search-dropdown
          [id]="'business-location'"
          [translations]="translationsSearchDropdown"
          [initialSelectedValue]="initialSelectedValue"
          [itemList]="businessLocations"
          [filterByProperty]="filterByProperty"
          [dropdownListSize]="4"
          (onSelectItem)="onSelectItem($event)"
          [disabled]="isUserInvited"
        ></lo-search-dropdown>

        <span class="info" *ngIf="isUserInvited">{{'CREATE.BUSINESS_LOCATION.INFO_INVITATION' | translate}}</span>

        <span *ngIf="!isSelfserviceTenant && form.get('tenant').valid" class="info">{{ 'CREATE.BUSINESS_LOCATION.MARKET.NOT_AVAILABLE_MODAL.CONTENT' | translate }}</span>
      </div>

      <lo-custom-select
        *ngIf="showIndiaCitySelectField"
        [list]="cityList"
        [initialSelectedItem]="initialSelectedCity"
        [translations]="translationsCitySelect"
        [dropdownListSize]="4"
        (onValueChange)="onCityChange($event)"
      ></lo-custom-select>

      <!-- Next Button -->
      <button (click)="nextStep()" class="button button--primary button--fluid m-t-12" type="button" [disabled]="!form.valid" id="step2-next">
        <span>{{ 'CREATE.NEXT' | translate }}</span>
        <i class="fal fa-chevron-right"></i>
      </button>
      <!-- ./Next Button -->
    </form>
  </div>
  <div class="page__graphics">
    <div class="image-wrapper">
      <img src="/assets/images/registration/business-location.svg" />
    </div>
    <div class="text">{{ 'CREATE.BUSINESS_LOCATION.GRAPHICS' | translate }}</div>
  </div>
</div>
