<div class="overlay" *ngIf="showModal" [@fadeInOutAnimation] (click)="openModal()"></div>

<div class="help-modal-button" (click)="openModal()" [@easeInOutBtn]="{value: (showModal ? 'show' : 'hide'), params:{maxWidth: '478px'}}">
  <span>{{ 'HELP_MODAL.OPEN_BUTTON' | translate}}</span>
  <i *ngIf="showModal" class="fal fa-chevron-right icon"></i>
</div>

<div class="help-modal-button-small-screens" (click)="openModal()" *ngIf="!showModal">
  <i class="fal fa-question-circle"></i>
</div>

<div class="wrapper__modal" [@easeInOut]="{value:(showIcon ? 'show' : 'hide'), params:{maxWidth: '480px'}}">
  <div class="close-button" (click)="openModal()">
    {{ 'HELP_MODAL.CLOSE_BUTTON' | translate}}
    <i class="fal fa-chevron-right icon"></i>
  </div>
  <div class="helpdesk-modal__window">
    <div class="headline m-b-24">{{ 'HELP_MODAL.HEADLINE' | translate}}</div>
    <div class="subline m-b-24">{{ 'HELP_MODAL.SUBLINE' | translate}}</div>

    <form [formGroup]="form" class="helpdesk-modal__window__form" id="helpdesk-form">
      <div class="input-wrap">
        <label id="helpdesk-input-label-name" class="input-label" for="helpdesk-form-name">{{ 'HELP_MODAL.INPUTS.FULL_NAME.LABEL' | translate}}</label>
        <input id="helpdesk-input-name" class="input" type="text" name="name" maxlength="100" id="helpdesk-form-name" formControlName="name" />
        <span id="helpdesk-input-error-name" *ngIf="form.get('name').touched && form.get('name').hasError('required')" class="input-error">
          {{ 'HELP_MODAL.INPUTS.FULL_NAME.ERROR_MESSAGE' | translate}}
        </span>
        <span id="helpdesk-input-error-name-invalid-char" *ngIf="form.get('name').touched && form.get('name').hasError('isSimpleStringValid')" class="input-error">
          {{ 'ERRORS.INVALID_SIMPLE_STRING' | translate }}
        </span>
      </div>

      <div class="input-wrap">
        <label id="helpdesk-input-label-email" class="input-label" for="helpdesk-form-name">{{ 'HELP_MODAL.INPUTS.EMAIL.LABEL' | translate}}</label>
        <input id="helpdesk-input-name" class="input" type="email" name="email" id="helpdesk-form-email" formControlName="email" />
        <span id="helpdesk-input-error-name" class="input-error">{{ 'HELP_MODAL.INPUTS.EMAIL.ERROR_MESSAGE' | translate}}</span>
      </div>

      <div class="input-wrap">
        <label id="helpdesk-input-label-message" class="input-label">{{ 'HELP_MODAL.INPUTS.MESSAGE.LABEL' | translate}}</label>
        <textarea id="helpdesk-input-name" class="textarea" name="message" id="helpdesk-form-message" formControlName="message"></textarea>
        <span id="helpdesk-input-error-name" class="input-error">{{ 'HELP_MODAL.INPUTS.MESSAGE.ERROR_MESSAGE' | translate}}</span>
      </div>

      <div class="send-message-button m-b-12">
        <button *ngIf="isFormSubmissionLoading" type="button" class="button button--savemode button--fluid">
          <i [ngClass]="{ 'c-plista-green': area === 'publishers', 'c-plista-blue': area === 'advertisers' }" class="fal fa-spinner fa-spin"></i>
        </button>
        <button
          *ngIf="!isFormSubmissionLoading"
          id="helpdesk-modal-controls-send"
          (click)="sendMessage(); $event.preventDefault()"
          class="button button--primary button--fluid"
          [ngClass]="{ 'button--primary--adv': area === 'advertisers' }"
          [disabled]="form.invalid"
        >
          {{ 'HELP_MODAL.SUBMIT_BUTTON' | translate}}
        </button>
      </div>

      <div class="submit-success-message" *ngIf="isFormSubmitted">
        <span>{{ 'HELP_MODAL.SEND_STATUS.SUCCESS.HEADLINE' | translate}} {{ 'HELP_MODAL.SEND_STATUS.SUCCESS.SUBLINE' | translate}}</span>
      </div>

      <div class="submit-error-message" *ngIf="isFormErrored">{{ 'HELP_MODAL.SEND_STATUS.ERROR.HEADLINE' | translate}} {{ 'HELP_MODAL.SEND_STATUS.ERROR.SUBLINE' | translate}}</div>

      <!-- <div class="more-info-text m-b-24">
        {{ 'HELP_MODAL.FOOTER' | translate}}
        <a href="">FAQ</a>
      </div> -->
      <img class="footer-img" src="assets/images/help_modal_graphic.svg" />
    </form>
  </div>

  <!-- <div class="success-page" *ngIf="isFormSubmitted">
    <div class="headline"></div>
    <div class="subline"></div>

    <ng-container *ngIf="!isFormErrored">
      <div>
        <h4 class="headline">{{ 'HELP_MODAL.SEND_STATUS.SUCCESS.HEADLINE' | translate}}</h4>
        <p class="subline">{{ 'HELP_MODAL.SEND_STATUS.SUCCESS.SUBLINE' | translate}}</p>
      </div>
      <i class="c-plista-blue" class="far fa-5x fa-check"></i>
    </ng-container>

    <ng-container *ngIf="isFormErrored">
      <div>
        <h4 id="contact-headline-3" class="hl-4 m-b-16">{{ 'HELP_MODAL.SEND_STATUS.ERROR.HEADLINE' | translate}}</h4>
        <p id="contact-text-4">{{ 'HELP_MODAL.SEND_STATUS.ERROR.SUBLINE' | translate}}</p>
      </div>
      <i class="c-plista-blue" class="far fa-5x fa-exclamation-triangle"></i>
    </ng-container>
    <button id="help-modal-modal-controls-close" (click)="openModal(); $event.preventDefault()" class="button button--secondary button--medium">
      {{ 'HELP_MODAL.CLOSE_BUTTON' | translate}}
    </button>
  </div> -->
</div>
<div class="box-shadow-wrap" [@easeInOut]="{value: (showModal ? 'show' : 'hide'), params:{maxWidth: '480px'}}">
  <div class="box-shadow-wrap-1"></div>
  <div class="box-shadow-wrap-2"></div>
  <div class="box-shadow-wrap-3"></div>
</div>

<!-- <div [@slidingDoorAnimation]="{value:slidingDoorValue,params:{inWidth:'100px',time:'1000ms'}}">Hello world</div> -->
