import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';
import { RequestStatus } from '../shared/registration-steps/step.service';
import { ToastService } from './toast.service';

@Component({
  selector: 'lo-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('easeInOut', [
      state(
        'hide',
        style({
          top: '-150px'
        })
      ),
      state(
        'show',
        style({
          top: '0px'
        })
      ),
      transition('show => hide', animate('300ms ease-out')),
      transition('hide => show', animate('300ms ease-in'))
    ])
  ]
})
export class ToastComponent implements OnDestroy {
  RequestStatus = RequestStatus;

  constructor(public toastService: ToastService) {}

  ngOnDestroy(): void {
    this.toastService.clear();
  }
}
