import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EMAIL_REGEXP } from './validation-pattern';

/** RegEX for URL Validation */
const IS_IPV4_OR_IPV6 = /^([0-9A-Fa-f]{1,4}:){7}[0-9A-Fa-f]{1,4}|(\d{1,3}\.){3}\d{1,3}$/i;
const INPUT_HAS_DOUBLE_DOTS = /^((?!\.\.).)*$/i;
const VALID_STRING = /^[\u00C0-\u017Fa-zA-Z0-9 _\-\.\/\[\]\(\)]+$/i;

const SIMPLE_VALID_STRING = /^(?!([0-9A-Fa-f]{1,4}:){7}[0-9A-Fa-f]{1,4}|(\d{1,3}\.){3}\d{1,3})[\u00C0-\u017Fa-zA-Z0-9 \-\.]+$/i;

function checkAddresses(address: string) {
  const domain = address.replace(/.*@/, '').toLowerCase();
  const restrictedMailAddress = ['plista.com', 'xaxis.com', 'groupm.com', 'groupm.de'];

  return restrictedMailAddress.filter(addresses => domain === addresses).toString();
}

export function excludeInternalUsers(control: FormControl<string>) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (checkAddresses(control.value?.trim())) {
    return {
      restrictedAddress: {
        valid: false
      }
    };
  }
  return null;
}

export function isEmailTaken(control: FormControl<string>): Observable<any> {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (control.dirty && EMAIL_REGEXP.test(control.value)) {
    // eslint-disable-next-line no-invalid-this
    return this.plistaApi.isEmailTaken(control.value?.trim()).pipe(
      switchMap((data: any) => {
        // eslint-disable-next-line no-invalid-this
        return this.plistaApi.getEncryptedEmail(data.data);
      }),
      switchMap((data: any) => {
        if (data.data === true) {
          return of({
            emailTaken: {
              valid: false
            }
          });
        }
        return of(null);
      })
    );
  } else {
    return of(null);
  }
}

export function minLength(control: FormControl<string>) {
  return control.value.length >= 16 ? null : { minLength: true };
}

export function hasLowercase(control: FormControl<string>) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return /[a-z]/.test(control.value) ? null : { hasLowercase: true };
}

export function hasUppercase(control: FormControl<string>) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return /[A-Z]/.test(control.value) ? null : { hasUppercase: true };
}

export function hasNumber(control: FormControl<string>) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return /[0-9]/.test(control.value) ? null : { hasNumber: true };
}

export function isStringValid(control: FormControl<string>): { isStringValid: { valid: boolean } } | null {
  if (!control.value) {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (!INPUT_HAS_DOUBLE_DOTS.test(control.value)) {
    return {
      isStringValid: {
        valid: false
      }
    };
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (IS_IPV4_OR_IPV6.test(control.value)) {
    return {
      isStringValid: {
        valid: false
      }
    };
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (!VALID_STRING.test(control.value)) {
    return {
      isStringValid: {
        valid: false
      }
    };
  } else {
    return null;
  }
}

export function isSimpleStringValid(control: FormControl<string>) {
  if (!control.value) {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return SIMPLE_VALID_STRING.test(control.value)
    ? null
    : {
        isSimpleStringValid: {
          valid: false
        }
      };
}
