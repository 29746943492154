<div class="main-header">
  <!-- header -->
  <header>
    <div>
      <a class="logo" routerLink="/">
        <img src="/assets/images/plista-logo-black.svg" />
      </a>
      <div class="title">{{translations?.title}}</div>
    </div>

    <!-- language toggle -->
    <lo-language-toggle class="language-toggle" [id]="'create-language-toggle'" [initialLanguage]="lang" (onLanguageChange)="onLanguageChange($event)"></lo-language-toggle>
    <!-- ./language toggle -->
  </header>
  <!-- ./header -->

  <!-- progress bar -->
  <div class="loading-bar">
    <span class="loading-bar__loaded loading-bar__loaded--success" [ngStyle]="{'width': progress + '%'}"></span>
  </div>
  <!-- ./progress bar -->
</div>
