<div class="page">
  <div class="page__content">
    <div class="content-subtitle">{{'CREATE.CONFIRM.SUBTITLE'| translate}}</div>
    <div class="content-title">{{'CREATE.CONFIRM.TITLE'| translate}}</div>
    <div class="content-message">{{'CREATE.CONFIRM.TEXT'| translate}}</div>

    <div class="content-email">{{userEmail}}</div>

    <div class="content-box">
      <div>{{'CREATE.CONFIRM.MESSAGE1'| translate}}</div>
      <div>
        {{'CREATE.CONFIRM.MESSAGE2' | translate}}
        <a (click)="resend()">{{'CREATE.CONFIRM.MESSAGE3' | translate}}</a>
        <span *ngIf="processing" class="spinner" [@fadeInOutAnimation]><i class="fad fa-spinner-third fa-spin"></i></span>
      </div>
    </div>
  </div>
  <div class="page__graphics">
    <div class="image-wrapper">
      <img src="/assets/images/registration/confirmation.svg" />
    </div>
  </div>
</div>
