<label class="input-label">{{ translations?.LABEL}}</label>

<div class="input-wrap" [@fadeInOutAnimation]>
  <div class="select-wrapper" (click)="showList()">
    <div class="select-field" id="{{ id }}-input-field">
      <span *ngIf="initialSelectedItem" id="{{ id }}-input-field-value">{{ translations?.TYPES[initialSelectedItem.toUpperCase()] }}</span>
      <span *ngIf="!initialSelectedItem" id="{{ id }}-input-field-placeholder">{{ translations?.PLACEHOLDER | translate }}</span>
    </div>
    <i class="fas fa-caret-down"></i>
  </div>
  <div class="list" [ngStyle]="{'max-height': 39 * dropdownListSize + 'px'}" *ngIf="showListItems">
    <div *ngFor="let item of list; index as i">
      <div id="{{ id }}-selection-{{ i }}" class="item" (click)="onitemSelect(item)" [ngClass]="{'selected-item': initialSelectedItem === item }">
        {{ translations?.TYPES[item] }}
      </div>
    </div>
  </div>
</div>
