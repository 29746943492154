<div class="mfa-content">
  <div class="mfa-content__notification">
    <i class="c-plista-green fa fa-check"></i>
    <span>{{"MFA_SETUP.RECOVERY_CODES.NOTIFICATION" | translate}}</span>
  </div>
  <div class="mfa-content__headline m-t-24">{{"MFA_SETUP.RECOVERY_CODES.TITLE" | translate}}</div>
  <div class="mfa-content__description m-t-24">{{"MFA_SETUP.RECOVERY_CODES.DESCRIPTION" | translate}}</div>
  <button class="button button--secondary download-btn m-t-24" (click)="downloadRecoveryCodes()">
    <i class="fa fa-arrow-to-bottom"></i>
    <span>{{"MFA_SETUP.RECOVERY_CODES.DOWNLOAD" | translate}}</span>
  </button>
  <button id="{{id}}-done-btn" [disabled]="fakeLoading" class="m-t-24 button button--primary button--fluid m-b-24" (click)="done()">
    <span class="spinner-container" *ngIf="fakeLoading">
      <i class="fal fa-spinner fa-spin"></i>
    </span>
    <span>{{"MFA_SETUP.RECOVERY_CODES.DONE" | translate}}</span>
  </button>
</div>
