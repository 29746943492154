export interface IUsergroups {
  advertiser: boolean;
  publisher: boolean;
  campaignManager: boolean;
}

export const USER_GROUPS: IUsergroups = {
  advertiser: false,
  publisher: false,
  campaignManager: false
};

const PUBLISHER_ROLES: string[] = ['Cooperations', 'Cooperations International', 'Cooperations Support', 'Cooperations Support Int', 'Selfservice Publisher'];
const CAMPAIGN_MANAGER: string[] = ['Campaign Management', 'Campaign Management Int'];
const ADVERTISER_ROLES: string[] = [...CAMPAIGN_MANAGER, 'Advertiser'];

export const isPublisher = (userRoles: string[]): boolean => {
  return userRoles.some(userRole => PUBLISHER_ROLES.some((publisherRole: string) => publisherRole === userRole));
};

export const isAdvertiser = (userRoles: string[]): boolean => {
  return userRoles.some(userRole => ADVERTISER_ROLES.some((advertiserRole: string) => advertiserRole === userRole));
};

export const isCampaignManager = (userRoles: string[]): boolean => {
  return userRoles.some(userRole => CAMPAIGN_MANAGER.some((role: string) => role === userRole));
};
