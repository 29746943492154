import { HttpRequestInterceptor } from './shared/http-request.interceptor';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
// Translation Module
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { AccountVerificationComponent } from './account-verification/account-verification.component';
import { AdvertiserInvitationComponent } from './advertiser-invitation/advertiser-invitation.component';
import { AppComponent } from './app.component';
import { BusinessLocationComponent } from './create-user/business-location/business-location.component';
import { BusinessTypeComponent } from './create-user/business-type/business-type.component';
import { PasswordComponent } from './create-user/choose-password/password.component';
import { CompanyNameComponent } from './create-user/company-name/company-name.component';
import { ConfirmComponent } from './create-user/confirm/confirm.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { RegistrationErrorComponent } from './create-user/error/registration-error.component';
import { CreateHeaderComponent } from './create-user/header/create-header.component';
import { PersonalInformationGraphicsComponent } from './create-user/personal-information/graphics/personal-information-graphics.component';
import { PersonalInformationComponent } from './create-user/personal-information/personal-information.component';
import { MarketingObjectiveComponent } from './create-user/marketing-objective/marketing-objective.component';
import { RoleComponent } from './create-user/role/your-role.component';
import { HelpModalComponent } from './helpmodal/helpmodal.component';
import { HelpModalService } from './helpmodal/helpmodal.service';
import { LanguageToggleComponent } from './language-toggle/language-toggle.component';
import { LoginWrapperComponent } from './login-wrapper/login-wrapper.component';
import { LoginComponent } from './login/login.component';
import { NoCountryConfirmationModalComponent } from './no-country-confirmation-modal/no-country-confirmation-modal.component';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { FilterByPropertyPipe } from './pipes/search-pipe/search.pipe';
import { AppRoutingModule } from './router/routing.module';
import { SearchDropdownComponent } from './search-dropdown/search-dropdown.component';
import { PlistaCookieService } from './shared/cookie.service';
import { CanActivateConfirm } from './shared/guards/confirm.guard';
import { CanActivateError } from './shared/guards/error.guard';
import { CanActivateStep } from './shared/guards/step.guard';
import { NavigationService } from './shared/navigation.service';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { PlistaApiService } from './shared/plista-api.service';
import { StepService } from './shared/registration-steps/step.service';
import { ToastComponent } from './toast/toast.component';
import { ToastService } from './toast/toast.service';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { MfaSetupComponent } from './mfa-setup/mfa-setup.component';
import { AuthAppRegistrationComponent } from './mfa-setup/auth-app-registration/auth-app-registration.component';
import { SetupInfoComponent } from './mfa-setup/setup-info/setup-info.component';
import { RecoveryCodeComponent } from './mfa-setup/recovery-code/recovery-code.component';
import { MfaCodeComponent } from './mfa-verification/mfa-code/mfa-code.component';
import { MfaVerificationComponent } from './mfa-verification/mfa-verification.component';
import { MfaRecoveryComponent } from './mfa-verification/mfa-recovery/mfa-recovery.component';
import { CookieService } from 'ngx-cookie-service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/translations/', `.json?${new Date().getTime()}`);
}

export const globalReCaptchaSettings: RecaptchaSettings = { size: 'invisible', siteKey: environment.recaptchaKey };

const sentryProviders = [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: false
    })
  },
  {
    provide: Sentry.TraceService,
    deps: [Router]
  },
  {
    provide: APP_INITIALIZER,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true
  }
];

export const pipes = [SafeHtmlPipe, FilterByPropertyPipe];

export const components = [
  AppComponent,
  LoginComponent,
  PasswordForgotComponent,
  CreateUserComponent,
  AccountVerificationComponent,
  PasswordResetComponent,
  NoCountryConfirmationModalComponent,
  LanguageToggleComponent,
  ToastComponent,
  HelpModalComponent,
  PersonalInformationComponent,
  PersonalInformationGraphicsComponent,
  CreateHeaderComponent,
  BusinessLocationComponent,
  BusinessTypeComponent,
  CompanyNameComponent,
  RoleComponent,
  PasswordComponent,
  ConfirmComponent,
  RegistrationErrorComponent,
  SearchDropdownComponent,
  AdvertiserInvitationComponent,
  LoginWrapperComponent,
  SearchDropdownComponent,
  MarketingObjectiveComponent,
  CustomSelectComponent,
  MfaSetupComponent,
  AuthAppRegistrationComponent,
  SetupInfoComponent,
  RecoveryCodeComponent,
  MfaCodeComponent,
  MfaVerificationComponent,
  MfaRecoveryComponent
];

export const modules = [
  BrowserModule,
  BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  AppRoutingModule,
  CommonModule,
  RecaptchaModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  })
];

export const guards = [CanActivateStep, CanActivateConfirm, CanActivateError];

export const services = [
  PlistaApiService,
  PlistaCookieService,
  HelpModalService,
  ToastService,
  StepService,
  NavigationService,
  CookieService,
  {
    provide: RECAPTCHA_SETTINGS,
    useValue: globalReCaptchaSettings
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpRequestInterceptor,
    multi: true
  }
];

@NgModule({
  declarations: [...components, ...pipes],
  imports: [...modules],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...services, ...guards, ...sentryProviders],
  bootstrap: [AppComponent]
})
export class AppModule {}
