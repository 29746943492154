import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { REGISTRATION_STEP_NAME } from '../../shared/registration-steps/step';
import { JobTitle, JOB_TITLE_LIST } from '../create-user';
import { fadeInOutAnimation, routeAnimation } from './../../shared/animations';
import { UserRoleFormGroup } from '../../shared/interfaces/forms';
import { StepComponent } from './../../shared/registration-steps/step.directive';
import { StepService } from './../../shared/registration-steps/step.service';
import { isSimpleStringValid } from './../../shared/validation-methods';
@Component({
  selector: 'lo-role',
  templateUrl: './your-role.component.html',
  styleUrls: ['./your-role.component.scss'],
  animations: [routeAnimation, fadeInOutAnimation]
})
export class RoleComponent extends StepComponent implements OnInit, OnDestroy {
  public form: FormGroup<UserRoleFormGroup>;
  public roles: JobTitle[] = JOB_TITLE_LIST;

  public showOtherInputField: boolean;

  public REGISTRATION_STEP_NAME = REGISTRATION_STEP_NAME;

  constructor(private fb: FormBuilder, public stepService: StepService) {
    super(stepService);
  }

  ngOnInit(): void {
    this.initForm(this.stepService.getStepDataByName(REGISTRATION_STEP_NAME.USER_ROLE));
  }

  toggleSelection(role: JobTitle): void {
    this.showExtraInputField(role.value);
    this.form.patchValue({
      role: role.value
    });
  }

  private initForm(stepData): void {
    this.form = this.fb.group({
      role: [stepData?.role || '', [Validators.required]],
      otherRole: ['']
    });

    this.showExtraInputField(stepData?.role as string, stepData?.otherRole as string);
  }

  showExtraInputField(type: string, value: string = null): void {
    if (type === 'Other') {
      this.showOtherInputField = true;
      this.form.get('otherRole').setValidators([Validators.required, isSimpleStringValid]);
      this.form.get('otherRole').setValue(value);
    } else {
      this.showOtherInputField = false;
      this.form.get('otherRole').clearValidators();
      this.form.get('otherRole').setValue(null);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
