<div class="mfa-content">
  <div class="mfa-content__headline m-t-24">{{"MFA_VERIFICATION.MFA_CODE.TITLE" | translate}}</div>
  <div class="mfa-content__description m-t-24">{{"MFA_VERIFICATION.MFA_CODE.DESCRIPTION" | translate}}</div>

  <form class="mfa-content__form m-t-24" [formGroup]="form" (ngSubmit)="confirmCode()">
    <div class="input-wrap m-b-4">
      <label class="input-label" for="code">{{"MFA_VERIFICATION.MFA_CODE.INPUT_LABEL" | translate}}</label>

      <input
        id="{{id}}-input-code"
        class="input"
        formControlName="code"
        [maxlength]="codeLength"
        autocomplete="off"
        #codeField
        [ngClass]="{'input--invalid' : submitStatus === LoadingStatus.FAILED}"
      />

      <span [@fadeInOutAnimation] class="input-error">
        <span *ngIf="form.get('code').hasError('pattern') && form.get('code').touched" id="{{id}}-invalid-input">
          {{ 'MFA_VERIFICATION.MFA_CODE.INVALID_PATTERN' | translate }}
        </span>
        <span *ngIf="submitStatus === LoadingStatus.FAILED" [@fadeInOutAnimation] id="{{id}}-code-error">{{ errorMessageKey | translate }}</span>
      </span>
    </div>
    <a class="recovery-link m-t-24" (click)="useAlternative()">{{ 'MFA_VERIFICATION.MFA_CODE.ALTERNATIVE' | translate }}</a>
    <button id="{{id}}-submit" [disabled]="!form.valid || submitStatus === LoadingStatus.LOADING" class="m-t-24 button button--primary button--fluid m-b-24" type="submit">
      <span class="spinner-container" *ngIf="submitStatus === LoadingStatus.LOADING">
        <i class="fal fa-spinner fa-spin"></i>
      </span>
      <span>{{"MFA_VERIFICATION.MFA_CODE.CONFIRM" | translate}}</span>
    </button>
  </form>
</div>
