export interface Country {
  urlkey: string;
  nameEN: string;
  nameDE: string;
}

export const countriesExcludedFromRegistration: string[] = ['mena', 'global', 'pl'];

export const COUNTRY_LIST: Country[] = [
  { urlkey: 'at', nameEN: 'Austria', nameDE: 'Österreich' },
  { urlkey: 'au', nameEN: 'Australia', nameDE: 'Australien' },
  { urlkey: 'be', nameEN: 'Belgium', nameDE: 'Belgien' },
  { urlkey: 'ca', nameEN: 'Canada', nameDE: 'Kanada' },
  { urlkey: 'ch', nameEN: 'Switzerland', nameDE: 'Schweiz' },
  { urlkey: 'cn', nameEN: 'China', nameDE: 'China' },
  { urlkey: 'cz', nameEN: 'Czech Republic', nameDE: 'Tschechische Republik' },
  { urlkey: 'de', nameEN: 'Germany', nameDE: 'Deutschland' },
  { urlkey: 'dk', nameEN: 'Denmark', nameDE: 'Dänemark' },
  { urlkey: 'es', nameEN: 'Spain', nameDE: 'Spanien' },
  { urlkey: 'fi', nameEN: 'Finland', nameDE: 'Finnland' },
  { urlkey: 'global', nameEN: 'Global', nameDE: 'Global' },
  { urlkey: 'hk', nameEN: 'Hong Kong', nameDE: 'Hongkong' },
  { urlkey: 'hr', nameEN: 'Croatia', nameDE: 'Kroatien' },
  { urlkey: 'mena', nameEN: 'Mena', nameDE: 'Mena' },
  { urlkey: 'am', nameEN: 'Armenia', nameDE: 'Armenien' },
  { urlkey: 'az', nameEN: 'Azerbaijan', nameDE: 'Aserbaidschan' },
  { urlkey: 'vg', nameEN: 'British Virgin Islands', nameDE: 'Britische Inseln' },
  { urlkey: 'cy', nameEN: 'Cyprus', nameDE: 'Zypern' },
  { urlkey: 'ee', nameEN: 'Estonia', nameDE: 'Estland' },
  { urlkey: 'fr', nameEN: 'France', nameDE: 'Frankreich' },
  { urlkey: 'gi', nameEN: 'Gibraltar', nameDE: 'Gibraltar' },
  { urlkey: 'ge', nameEN: 'Georgia', nameDE: 'Georgien' },
  { urlkey: 'in', nameEN: 'India', nameDE: 'Indien' },
  { urlkey: 'ie', nameEN: 'Ireland', nameDE: 'Irland' },
  { urlkey: 'il', nameEN: 'Israel', nameDE: 'Israel' },
  { urlkey: 'it', nameEN: 'Italy', nameDE: 'Italien' },
  { urlkey: 'kz', nameEN: 'Kazakhstan', nameDE: 'Kasachstan' },
  { urlkey: 'kg', nameEN: 'Kyrgyzstan', nameDE: 'Kirgisistan' },
  { urlkey: 'li', nameEN: 'Liechtenstein', nameDE: 'Liechtenstein' },
  { urlkey: 'lt', nameEN: 'Lithuania', nameDE: 'Litauen' },
  { urlkey: 'lu', nameEN: 'Luxemburg', nameDE: 'Luxemburg' },
  { urlkey: 'my', nameEN: 'Malaysia', nameDE: 'Malaysia' },
  { urlkey: 'mt', nameEN: 'Malta', nameDE: 'Malta' },
  { urlkey: 'md', nameEN: 'Moldova', nameDE: 'Moldawien' },
  { urlkey: 'ma', nameEN: 'Morocco', nameDE: 'Marokko' },
  { urlkey: 'nl', nameEN: 'Netherlands', nameDE: 'Niederlande' },
  { urlkey: 'nz', nameEN: 'New Zealand', nameDE: 'Neuseeland' },
  { urlkey: 'gr', nameEN: 'Greece', nameDE: 'Griechenland' },
  { urlkey: 'no', nameEN: 'Norway', nameDE: 'Norwegen' },
  { urlkey: 'pl', nameEN: 'Poland', nameDE: 'Polen' },
  { urlkey: 'pt', nameEN: 'Portugal', nameDE: 'Portugal' },
  { urlkey: 'ru', nameEN: 'Russia', nameDE: 'Russland' },
  { urlkey: 'ro', nameEN: 'Romania', nameDE: 'Rumänien' },
  { urlkey: 'sg', nameEN: 'Singapore', nameDE: 'Singapur' },
  { urlkey: 'hu', nameEN: 'Hungary', nameDE: 'Ungarn' },
  { urlkey: 'sc', nameEN: 'Seychelles', nameDE: 'Seychellen' },
  { urlkey: 'sk', nameEN: 'Slovakia', nameDE: 'Slowakei' },
  { urlkey: 'si', nameEN: 'Slovenia', nameDE: 'Slowenien' },
  { urlkey: 'se', nameEN: 'Sweden', nameDE: 'Schweden' },
  { urlkey: 'tj', nameEN: 'Tajikistan', nameDE: 'Tadschikistan' },
  { urlkey: 'th', nameEN: 'Thailand', nameDE: 'Thailand' },
  { urlkey: 'tr', nameEN: 'Turkey', nameDE: 'Türkei' },
  { urlkey: 'tm', nameEN: 'Turkmenistan', nameDE: 'Turkmenistan' },
  { urlkey: 'gb', nameEN: 'United Kingdom', nameDE: 'Vereinigtes Königreich' },
  { urlkey: 'us', nameEN: 'United States', nameDE: 'Vereinigtes Staaten' },
  { urlkey: 'ua', nameEN: 'Ukraine', nameDE: 'Ukraine' },
  { urlkey: 'uz', nameEN: 'Uzbekistan', nameDE: 'Usbekistan' },
  { urlkey: 'by', nameEN: 'Belarus', nameDE: 'Weißrussland' },
  { urlkey: 'tw', nameEN: 'Taiwan', nameDE: 'Taiwa' }
];
