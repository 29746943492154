import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { INPUT_TYPE } from '../constants/application.constants';
import { fadeInOutAnimation, routeAnimation } from '../shared/animations';
import { ERROR_CODES, LoginRes, QrCode, TOKEN_TYPE } from '../shared/interfaces/common';
import { LoginFormGroup } from '../shared/interfaces/forms';
import { setLanguage } from '../shared/language';
import { PlistaApiService } from '../shared/plista-api.service';
import { Store } from '../shared/store';
import { Unsubscriber } from '../shared/unsubscriber';
import { UtilsService } from '../shared/utils.service';
import { EMAIL_REGEXP } from '../shared/validation-pattern';
import { NavigationParams, NavigationService } from './../shared/navigation.service';

@Component({
  selector: 'lo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [routeAnimation, fadeInOutAnimation]
})
export class LoginComponent extends Unsubscriber implements OnInit, OnDestroy {
  // @ViewChild('captchaRef') captchaRef;
  @HostBinding('@routeAnimation') routeAnimation = true;

  public errorMessage: string;
  public submitAttempt: boolean;
  public tenant: string;
  public form: FormGroup<LoginFormGroup>;
  public showPassword: boolean;
  public processing: boolean;
  public showResendVerification;
  public lang: string;
  public passwordInputType: INPUT_TYPE;
  public showForm: boolean;
  private isNewUser: boolean;
  public isLoggingIn: boolean;

  public isMfaSetupVisible: boolean;
  public isMfaRequired: boolean;
  public errorMessageKey: string;
  public qrCode: QrCode;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private plistaApi: PlistaApiService,
    private title: Title,
    private translate: TranslateService,
    private navigationService: NavigationService
  ) {
    super();
    this.title.setTitle('Login | plista');
    this.lang = this.translate.currentLang;
    this.showResendVerification = false;
    /* get tenant from origin + set redirect */
    this.subscriptions.push(
      this.route.queryParams.subscribe(param => {
        if (param.origin) {
          Store.redirectUrl = UtilsService.isOriginUrlValid(param.origin as string) ? param.origin : '';
        }

        if (param.verified === 'true') {
          this.isNewUser = true;
        }
      })
    );

    /* initializing variables */
    this.showPassword = false;
    this.checkUserAndNavigate();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick() {
    if (this.processing && !this.isLoggingIn) {
      this.processing = false;
    }
  }

  ngOnInit(): void {
    this.passwordInputType = INPUT_TYPE.PASSWORD;
    this.processing = false;

    this.translate.onLangChange.subscribe(lang => {
      this.lang = lang.lang;
    });
  }

  showPasswordClicked(val: boolean): void {
    this.showPassword = val;
    this.passwordInputType = val ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD;
  }

  onLanguageChange(lang): void {
    this.lang = lang;
    setLanguage(this.translate, this.lang);
  }

  initForm() {
    this.showForm = true;
    this.form = this.fb.group({
      email: [Store.email || '', [Validators.required, Validators.pattern(EMAIL_REGEXP)]],
      password: ['', [Validators.required]],
      rememberMe: [false, []]
    });

    this.form.markAsUntouched();
    this.form.markAsPristine();

    UtilsService.hideAppLoader();
  }

  login(): void {
    if (this.form.valid) {
      this.isLoggingIn = true;
      const userEmail: string = this.form.get('email').value;
      this.subscriptions.push(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.plistaApi.loginUser({ email: this.form.get('email').value, password: this.form.get('password').value }).subscribe(
          (data: LoginRes) => {
            this.onLoginSuccess(data, userEmail);
          },
          (error: { error: LoginRes }) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            this.onLoginError(error?.error?.['errorCode']);

            // Not needed anymore
            // necessary when login error message are available
            // this.errorMessage = this.getLoginErrorMessage(error);
            // this.showResendVerification = this.isUserDeactivated(error);
            // if (this.captchaRef) {
            //   this.captchaRef.reset();
            // }
            // this.processing = false;
            // this.isLoggingIn = false;
            // Store.email = this.form.get('email').value;
          }
        )
      );
    } else {
      this.processing = false;
      this.isLoggingIn = false;
      this.submitAttempt = true;
    }
  }

  onLoginError(errorCode: ERROR_CODES) {
    if (Object.values(ERROR_CODES).includes(errorCode)) {
      this.errorMessageKey = `LOGIN.ERROR.${errorCode}`;
      this.showResendVerification = errorCode === ERROR_CODES.DEACTIVATED_USER ? true : false;
    } else {
      this.errorMessageKey = `LOGIN.ERROR.UNKNOWN_ERROR`;
    }

    // if (this.captchaRef) {
    //   this.captchaRef.reset();
    // }
    this.processing = false;
    this.isLoggingIn = false;
  }

  private showMfaSetup(data: LoginRes, userEmail: string): void {
    this.plistaApi.temporaryToken = data.access_token;
    this.plistaApi.email = userEmail;
    this.qrCode = {
      svg: data.svg,
      url: data.url,
      secret: data.secret_key
    };
    this.showForm = false;
    this.isMfaSetupVisible = true;
  }

  onLoginSuccess(loginRes: LoginRes, userEmail: string): void {
    if (loginRes.token_type === TOKEN_TYPE.SETUP_TOKEN) {
      this.showMfaSetup(loginRes, userEmail);
    } else if (loginRes.token_type === TOKEN_TYPE.CHALLENGE_TOKEN) {
      this.showMfaVerification(loginRes.access_token, userEmail);
    } else {
      this.checkUserAndNavigate();
    }
  }

  showMfaVerification(accessToken: string, userEmail: string) {
    this.plistaApi.temporaryToken = accessToken;
    this.plistaApi.email = userEmail;
    this.showForm = false;
    this.isMfaRequired = true;
  }

  // executeCaptcha() {
  //   this.submitAttempt = true;

  //   if (this.form.valid) {
  //     this.processing = true;
  //     // when reCaptcha responds, create will be called programmaticly
  //     // function call is in the markup: (resolved)="create($event)"
  //     this.captchaRef.execute();
  //   }
  // }

  checkUserAndNavigate() {
    this.subscriptions.push(
      this.plistaApi.getUser().subscribe(
        user => {
          if (!user) {
            this.reset();
          } else {
            const navigationParam: NavigationParams = { id: user.data.id, roles: user.data.roles, masterTenant: user.data.masterTenant, isNewUser: this.isNewUser };
            this.navigationService.navigateToApp(navigationParam);
          }
        },
        () => {
          /* if they are wrong or old cookies, delete them */
          this.reset();
        }
      )
    );
  }

  //  TODO: Check if it has side effects ?
  private reset() {
    this.initForm();
    this.isMfaSetupVisible = false;
    this.isMfaRequired = false;
    this.submitAttempt = false;
    this.processing = false;
    this.errorMessageKey = null;
    // if (this.captchaRef) {
    //   this.captchaRef.reset();
    // }
    setTimeout(() => {
      this.showForm = true;
      this.initForm();
    }, 300);
  }

  trimSpaces(formControl: AbstractControl) {
    if (formControl.value) {
      formControl.setValue(formControl.value.trim());
    }
  }

  resendVerification(): void {
    Store.email = this.form.get('email').value;
    void this.router.navigate(['/account-verification'], { skipLocationChange: true, queryParams: { resendVerification: true } });
  }

  mfaVerificationComplete(): void {
    this.checkUserAndNavigate();
  }

  public redirectTo(url): void {
    const redirectUrl = `${url}/${this.form.get('tenant').value}`;
    window.location.assign(redirectUrl);
  }

  public tokenExpired() {
    this.plistaApi.resetTemporaryToken();
    this.reset();
  }
}
