import { animate, AnimationTriggerMetadata, group, query, style, transition, trigger } from '@angular/animations';

// Component transition animations
export const routeAnimation: AnimationTriggerMetadata = trigger('routeAnimation', [transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))])]);

export const fadeInOutAnimation: AnimationTriggerMetadata = trigger('fadeInOutAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))])
]);

export const fadeInOutAnimationSlow: AnimationTriggerMetadata = trigger('fadeInOutAnimationSlow', [
  transition(':enter', [style({ opacity: 0 }), animate('800ms', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('800ms', style({ opacity: 0 }))])
]);

export const slideUpDown: AnimationTriggerMetadata = trigger('slide', [
  transition(':enter', [style({ height: 0 }), animate('300ms', style({ height: '*' }))]),
  transition(':leave', [style({ height: '*' }), animate('300ms', style({ height: 0 }))])
]);

export const routerSliderAnimation = trigger('routerAnimations', [
  transition(':increment', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'fixed',
          right: 0,
          width: '100%'
        })
      ],
      { optional: true }
    ),
    query(':enter', [style({ right: '-100%' })]),
    group([query(':leave', [animate('500ms ease', style({ right: '100%' }))], { optional: true }), query(':enter', [animate('500ms ease', style({ right: '0%' }))])])
  ]),
  transition(':decrement', [
    query(
      ':enter, :leave',
      [
        style({
          position: 'fixed',
          left: 0,
          width: '100%'
        })
      ],
      { optional: true }
    ),
    query(':enter', [style({ left: '-100%' })]),
    group([query(':leave', [animate('500ms ease', style({ left: '100%' }))], { optional: true }), query(':enter', [animate('500ms ease', style({ left: '0%' }))])])
  ])
]);
