<div class="wrapper">
  <div class="wrapper__left">
    <div class="animation">
      <div class="image-one">
        <img src="/assets/images/demo-one.png" />
      </div>
      <div class="image-two">
        <div class="want-button">I want it!</div>
        <img src="/assets/images/login-demo.gif" />
      </div>
      <div class="image-three">
        <img src="/assets/images/demo-two.png" />
      </div>
    </div>
  </div>
  <div class="wrapper__right">
    <!-- login form -->
    <div class="wrapper__login-form" *ngIf="showForm">
      <div class="headline">{{ 'LOGIN.HEADLINE' | translate }}</div>
      <form [formGroup]="form" id="login-form" (ngSubmit)="login()">
        <div class="input-wrap m-b-4">
          <label class="input-label" for="login_input-email">{{ 'LOGIN.EMAIL.LABEL' | translate }}</label>
          <input
            class="input"
            type="email"
            [@fadeInOutAnimation]
            [ngClass]="{ 'input--invalid': submitAttempt && form.get('email').invalid }"
            formControlName="email"
            id="login_input-email"
            (blur)="trimSpaces(form.get('email'))"
          />
          <span *ngIf="submitAttempt && form.get('email').invalid" [@fadeInOutAnimation] class="form_error">{{ 'LOGIN.EMAIL.ERROR' | translate }}</span>
        </div>

        <div class="input-wrap input-wrap--password m-b-24 m-t-12">
          <label class="input-label" for="login_input-password">{{ 'LOGIN.PASSWORD.LABEL' | translate }}</label>
          <i *ngIf="!showPassword" (click)="showPasswordClicked(true)" class="fal fa-eye-slash password-input-eye"></i>
          <i *ngIf="showPassword" (click)="showPasswordClicked(false)" class="fal fa-eye password-input-eye"></i>
          <input
            class="input"
            type="{{ passwordInputType }}"
            formControlName="password"
            id="login_input-password"
            [ngClass]="{ 'input--invalid': submitAttempt && form.get('password').invalid }"
          />
          <span *ngIf="submitAttempt && form.get('password').invalid" [@fadeInOutAnimation] class="form_error">{{ 'LOGIN.PASSWORD.ERROR' | translate }}</span>
        </div>
        <div class="forgot-password">
          <a routerLink="/forgot-password" id="login_link-forgot-password">{{ 'LOGIN.FORGOT' | translate }}</a>
        </div>

        <button class="button button--primary button--fluid m-b-24" type="submit" [disabled]="processing" id="login_button-login">
          <span class="spinner-container" *ngIf="processing">
            <i class="fal fa-spinner fa-spin"></i>
          </span>
          <ng-container *ngIf="!processing">{{ 'LOGIN.SUBMIT' | translate }}</ng-container>
          <ng-container *ngIf="processing">{{ 'LOGIN.SUBMIT_PROCESSING' | translate }}</ng-container>
        </button>

        <!-- <re-captcha size="invisible" #captchaRef="reCaptcha" style="display: none" (resolved)="login($event)"></re-captcha> -->

        <p *ngIf="errorMessageKey" class="error_message">
          {{ errorMessageKey | translate }}
          <a (click)="resendVerification()" *ngIf="showResendVerification" id="login-resend-verification-button">{{'EMAIL_VERIFICATION.RESEND' | translate }}</a>
        </p>

        <!-- TODO: Remember me -->
        <!-- <div class="input-wrap m-b-24 m-t-12">
          <input type="checkbox" class="checkbox" id="checkbox-remember-me" formControlName="rememberMe" />
          <label class="remember-me-label" for="checkbox-remember-me">{{ 'LOGIN.REMEMBER_ME' | translate }}</label>
        </div> -->
      </form>

      <div class="create-account subline">
        {{ 'LOGIN.SUBLINE' | translate }}
        <a routerLink="{{ 'LOGIN.ROUTER_LINK' | translate }}" class="create-account link" id="create-account-btn">{{ 'LOGIN.GO_TO' | translate }}</a>
      </div>
    </div>
    <!-- login form -->

    <!-- mfa setup -->

    <div class="wrapper__mfa" [@fadeInOutAnimation] *ngIf="isMfaSetupVisible">
      <lo-mfa-setup (onDoneClicked)="checkUserAndNavigate()" (onTokenExpired)="tokenExpired()" [qrCode]="qrCode" id="mfa-setup-page"></lo-mfa-setup>
    </div>
    <div class="wrapper__mfa" [@fadeInOutAnimation] *ngIf="isMfaRequired">
      <lo-mfa-verification (onComplete)="mfaVerificationComplete()" (onTokenExpired)="tokenExpired()" id="mfa-verification-page"></lo-mfa-verification>
    </div>
    <!-- ./mfa setup -->

    <!-- footer -->
    <footer>
      <a href="https://www.plista.com/imprint" target="_blank">{{"CREATE.LINK.IMPRINT" | translate}}</a>
      <div class="round"></div>
      <a href="https://www.plista.com/about/privacy" target="_blank">{{"CREATE.LINK.PRIVACY_POLICY" | translate}}</a>
    </footer>
    <!-- ./footer -->
  </div>
</div>
