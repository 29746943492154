<div class="wrapper">
  <!-- RESET PASSWORD FORM SECTION -->
  <ng-container *ngIf="hash && !hashExpired && !notAllowed && !resetSuccess">
    <div class="wrapper__form">
      <div>
        <form *ngIf="showForm" [formGroup]="form" id="password-reset-form">
          <div class="headline">{{ 'RESET_PASSWORD.FORM_PAGE.HEADLINE' | translate }}</div>
          <div class="subline">{{ 'RESET_PASSWORD.FORM_PAGE.SUBLINE' | translate }}</div>

          <div class="input-wrap input-wrap--password m-b-16">
            <label class="input-label" for="login_input-password">{{ 'RESET_PASSWORD.FORM_PAGE.INPUT.NEW_PASSWORD.LABEL' | translate }}</label>
            <i *ngIf="!showNewPassword" (click)="showNewPasswordClicked(true)" class="fal fa-eye-slash password-input-eye"></i>
            <i *ngIf="showNewPassword" (click)="showNewPasswordClicked(false)" class="fal fa-eye password-input-eye"></i>
            <input
              (focus)="passwordFieldFocused = true"
              (blur)="passwordFieldFocused = false"
              class="input"
              type="{{ newPasswordInputType }}"
              [placeholder]="'RESET_PASSWORD.FORM_PAGE.INPUT.NEW_PASSWORD.PLACEHOLDER' | translate"
              formControlName="password"
              id="reset_input-password"
              [ngClass]="{
                        'input--invalid':
                          (submitAttempt && password.hasError('required')) ||
                          (!password.hasError('required') && password.hasError('minLength'))
                      }"
            />

            <span *ngIf="submitAttempt && password.hasError('required')" [@fadeInOutAnimation] class="form_error">{{ 'CREATE.PASSWORD.ERROR' | translate }}</span>
            <span
              class="form_error"
              *ngIf="
                        submitAttempt &&
                        !password.hasError('required') &&                        
                          password.hasError('minLength')
                      "
              [@fadeInOutAnimation]
            >
              {{ 'CREATE.PASSWORD.ERROR_SECURITY' | translate }}
            </span>
            <ul class="validation-hint-list margin-bottom-30">
              <li class="validation-hint-list__item" [class.invalid]="password.hasError('minLength')">
                <span>{{ 'CREATE.PASSWORD.HINT.LENGTH' | translate }}</span>
              </li>
            </ul>
          </div>

          <div class="input-wrap m-t-0 input-wrap--password">
            <label class="input-label" for="login_input-password-confirm">{{ 'RESET_PASSWORD.FORM_PAGE.INPUT.CONFIRM_PASSWORD.LABEL' | translate }}</label>
            <i *ngIf="!showConfirmPassword" (click)="showConfirmPasswordClicked(true)" class="fal fa-eye-slash password-input-eye"></i>
            <i *ngIf="showConfirmPassword" (click)="showConfirmPasswordClicked(false)" class="fal fa-eye password-input-eye"></i>
            <input
              class="input"
              type="{{ confirmPasswordInputType }}"
              [placeholder]="'RESET_PASSWORD.FORM_PAGE.INPUT.CONFIRM_PASSWORD.PLACEHOLDER' | translate"
              formControlName="confirm_password"
              id="reset_input-password-confirm"
              [ngClass]="{
                        'input--invalid':
                          form.hasError('required') ||
                          (form.hasError('areEqual') &&
                          (confirmPassword.dirty || confirmPassword.touched))
                      }"
            />
            <span
              *ngIf="
                        form.hasError('areEqual') &&
                        (confirmPassword.dirty || confirmPassword.touched)
                      "
              [@fadeInOutAnimation]
              class="form_error"
            >
              {{ 'RESET_PASSWORD.FORM_PAGE.INPUT.ERROR.ERROR_EQUAL' | translate }}
            </span>
            <span *ngIf="submitAttempt && confirmPassword.hasError('required')" [@fadeInOutAnimation] class="form_error">
              {{ 'RESET_PASSWORD.FORM_PAGE.INPUT.ERROR.ERROR_REPEAT' | translate }}
            </span>
          </div>
        </form>
        <button class="button button--primary button--fluid" (click)="resetPassword()" [disabled]=" !form.valid || processing" id="reset_button-login">
          <span class="spinner-container" *ngIf="processing">
            <i class="fal fa-spinner fa-spin"></i>
          </span>
          <ng-container *ngIf="!processing">{{ 'RESET_PASSWORD.FORM_PAGE.BUTTON' | translate }}</ng-container>
          <ng-container *ngIf="processing">{{ 'RESET_PASSWORD.FORM_PAGE.PROCESSING' | translate }}</ng-container>
        </button>
        <p *ngIf="errorMessage" [@fadeInOutAnimation] class="ta-center subbody subbody--light error_message m-t-8">{{ errorMessage }}</p>
      </div>
    </div>
  </ng-container>

  <!-- RESET SUCCESS -->
  <div class="wrapper__reset-success" *ngIf="resetSuccess" [@fadeInOutAnimation]>
    <div class="content">
      <div class="headline">{{ 'RESET_PASSWORD.RESET_SUCCESS.HEADLINE' | translate }}</div>
      <div class="subline">{{ 'RESET_PASSWORD.RESET_SUCCESS.SUBLINE' | translate }}</div>

      <button class="button button--primary primary button--fluid" (click)="navigateToLogin()">{{ 'RESET_PASSWORD.RESET_SUCCESS.CONTINUE_TO_LOGIN' | translate }}</button>
    </div>
  </div>

  <!-- RESET SUCCESS -->

  <!-- RESET PASSWORD EXPIRED LINK SECTION -->
  <ng-container *ngIf="hash && hashExpired && !notAllowed">
    <div class="wrapper__link-expired">
      <div class="content">
        <div class="headline">{{ 'RESET_PASSWORD.LINK_EXPIRED_PAGE.HEADLINE' | translate }}</div>
        <div class="subline">{{ 'RESET_PASSWORD.LINK_EXPIRED_PAGE.SUBLINE' | translate }}</div>
        <button class="button button--primary button--fluid" id="reset_expired-button" routerLink="/forgot-password" [disabled]="processing" id="reset_button-resend-link">
          {{ 'RESET_PASSWORD.LINK_EXPIRED_PAGE.BUTTON' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <!-- RESET PASSWORD ERROR SECTION -->
  <ng-container *ngIf="notAllowed">
    <div class="wrapper__error">
      <div class="content">
        <div class="headline">{{ 'RESET_PASSWORD.ERROR_PAGE.HEADLINE' | translate }}</div>
        <div class="subline">{{ 'RESET_PASSWORD.ERROR_PAGE.SUBLINE' | translate }}</div>

        <button class="button button--primary button--fluid" routerLink="/forgot-password" [disabled]="processing" id="reset_button-resend-link">
          {{ 'RESET_PASSWORD.LINK_EXPIRED_PAGE.BUTTON' | translate }}
        </button>
      </div>
      <img class="image" src="assets/images/bug_pic.svg" />
    </div>
  </ng-container>
</div>
