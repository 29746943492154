<header [ngClass]="{'dark-design': transKey !== 'LOGIN'}">
  <div class="headline">
    <div class="wrapper__logo">
      <img class="logo logo-white" src="assets/images/plista-logo-white.svg" />
      <img class="logo logo-black" src="assets/images/plista-logo-black.svg" />

      <div class="screen-description">{{ 'LOGIN.HEADER.' + transKey + '.HEADLINE' | translate }}</div>
    </div>
    <lo-language-toggle class="language-toggle" [id]="'login-language-toggle'" [initialLanguage]="lang" (onLanguageChange)="onLanguageChange($event)"></lo-language-toggle>
  </div>

  <div class="subline" *ngIf="transKey !== 'LOGIN'">
    {{ 'LOGIN.HEADER.' + transKey + '.SUBLINE' | translate }}
    <a routerLink="{{ 'LOGIN.HEADER.' + transKey + '.ROUTER_LINK' | translate }}" class="link" id="create-account-btn">{{ 'LOGIN.HEADER.' + transKey + '.GO_TO' | translate }}</a>
  </div>
</header>

<div class="wrapper">
  <router-outlet></router-outlet>
</div>
