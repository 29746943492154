import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { getStepOrderByRouteName, REGISTRATION_STEP_ROUTE_NAME } from '../registration-steps/step';
import { StepService } from './../registration-steps/step.service';

@Injectable()
export class CanActivateConfirm implements CanActivate {
  constructor(private router: Router, private stepService: StepService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const routeName = route.routeConfig.path as REGISTRATION_STEP_ROUTE_NAME;
    if (this.stepService.registrationData && this.stepService.registrationData.email) {
      this.stepService.index = getStepOrderByRouteName(routeName);
      return true;
    }

    return this.router.parseUrl(`/`);
  }
}
