import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ItemList, ItemSelect, SearchDropDownTranslations } from './search-dropdown';

@Component({
  selector: 'lo-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent implements OnInit {
  @Input() id: string;
  @Input() translations: SearchDropDownTranslations;
  @Input() set initialSelectedValue(initialSelectedValue: string) {
    if (initialSelectedValue) {
      this.searchText = initialSelectedValue;
      this.initialSelectedItem = initialSelectedValue;
    }
  }
  @Input() filterByProperty: string;
  @Input() itemList: ItemList[];
  @Input() dropdownListSize: number;
  @Input() disabled: boolean;

  @Output() onSelectItem = new EventEmitter();

  public searchText: string;
  public initialSelectedItem: string;
  public selectedItem: ItemSelect;
  public showItemList: boolean;
  public isValid: boolean;
  public tempStoreText: string;

  constructor(private elmentRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (this.showItemList) {
      const clickedInside = this.elmentRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.onFocusOut();
      }
    }
  }

  ngOnInit(): void {
    this.showItemList = false;
  }

  onFocusOut(): void {
    this.showItemList = false;
    this.searchText = this.tempStoreText;
  }

  itemSelected(item: ItemSelect, transItem: string): void {
    this.selectedItem = item;
    this.onSelectItem.emit(item);
    this.searchText = transItem;
    this.showItemList = false;
    this.initialSelectedItem = item.nameDE;
    this.tempStoreText = transItem;
  }

  onSearchFocus(): void {
    this.showItemList = true;
    this.tempStoreText = this.searchText;
    this.searchText = '';
  }
}
