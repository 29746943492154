import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterPipe' })
export class FilterByPropertyPipe implements PipeTransform {
  transform(items: any[], searchText: string, property: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText || searchText.length === 0) {
      return items;
    }

    searchText = searchText.toLocaleLowerCase();

    if (property) {
      return items.filter(item => {
        if (item.hasOwnProperty(property) && typeof item[property] !== 'object') {
          return item[property].toLocaleLowerCase().includes(searchText);
        } else {
          return item[property][property].toLocaleLowerCase().includes(searchText);
        }
      });
    }
    return null;
  }
}
