<div class="wrapper__page" [@fadeInOutAnimation]>
  <div class="content">
    <div class="headline">
      {{ 'ADVERTISER_INVITATION.HEADLINE' | translate}}
      <span *ngIf="fullName">, {{fullName}}</span>
    </div>
    <div class="subline">{{ 'ADVERTISER_INVITATION.SUBLINE' | translate : {invitedFrom: invitedFromFullName, advertiserName: advertiser} }}</div>
    <div class="content__box" *ngIf="userExists">
      <div>{{ 'ADVERTISER_INVITATION.CONTENT.USER_EXISTS' | translate }}</div>
    </div>
    <div class="content__box" *ngIf="!userExists">
      <div>{{ 'ADVERTISER_INVITATION.CONTENT.USER_DOES_NOT_EXIST' | translate : {invitedFrom: invitedFromFullName} }}</div>
    </div>

    <button class="button button--primary button--fluid m-t-24" (click)="goTo()">
      <span *ngIf="userExists">{{'LOGIN.SUBMIT' | translate}}</span>
      <span *ngIf="!userExists">{{'CREATE.SUBMIT' | translate}}</span>
    </button>
  </div>
</div>
