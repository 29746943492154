<lo-create-header [progress]="progress" [translations]="translations?.HEADER"></lo-create-header>
<!-- breadcrumb -->
<div class="breadcrumb">
  <div *ngIf="currentStepIndex === 0" class="initial-text">
    <span>{{"CREATE.PERSONAL_INFORMATION.HAS_ACCOUNT" | translate}}</span>
    <a routerLink="/">{{"CREATE.PERSONAL_INFORMATION.LOGIN" | translate}}</a>
  </div>
  <a *ngIf="isBackBtnVisible" class="back-btn" (click)="back()">
    <i class="fal fa-chevron-left"></i>
    <span>{{"CREATE.BACK" | translate}}</span>
  </a>
</div>
<!-- ./breadcrumb -->
<div class="page-container" [@routerAnimations]="prepareRouteTransition(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</div>

<footer>
  <a href="{{legalLink?.imprint}}" target="_blank">{{"CREATE.LINK.IMPRINT" | translate}}</a>
  <div class="round"></div>
  <a href="{{legalLink?.privacy}}" target="_blank">{{"CREATE.LINK.PRIVACY_POLICY" | translate}}</a>
  <div class="round" *ngIf="businessLocation"></div>
  <a href="{{legalLink?.termsAndConditions}}" target="_blank" *ngIf="businessLocation">{{"CREATE.LINK.TERMS_AND_CONDITIONS" | translate}}</a>
</footer>
