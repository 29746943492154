import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fadeInOutAnimation } from '../shared/animations';
import { LoginRes, MODE_OF_VERIFICATION } from '../shared/interfaces/common';

@Component({
  selector: 'lo-mfa-verification',
  templateUrl: './mfa-verification.component.html',
  styleUrls: ['./mfa-verification.component.scss'],
  animations: [fadeInOutAnimation]
})
export class MfaVerificationComponent {
  @Input() id: string;
  @Output() onComplete: EventEmitter<LoginRes> = new EventEmitter<LoginRes>();
  @Output() onTokenExpired = new EventEmitter();

  mode: MODE_OF_VERIFICATION = MODE_OF_VERIFICATION.AUTH_APP;
  MODE_OF_VERIFICATION = MODE_OF_VERIFICATION;

  codeConfirmed(): void {
    this.onComplete.emit();
  }

  useAlternative(mode: MODE_OF_VERIFICATION): void {
    this.mode = mode;
  }

  tokenExpired() {
    this.onTokenExpired.emit();
  }
}
