import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { getStepOrderByRouteName, REGISTRATION_STEP_ROUTE_NAME } from '../registration-steps/step';
import { StepService } from './../registration-steps/step.service';

@Injectable()
export class CanActivateStep implements CanActivate {
  constructor(private router: Router, private stepService: StepService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const routeName = route.routeConfig.path as REGISTRATION_STEP_ROUTE_NAME;
    const isValid = this.stepService.isValid(routeName);
    if (!isValid) {
      const stepToNavigate = this.stepService.getInvalidSteps(routeName)[0];
      this.stepService.index = stepToNavigate.order;
      return this.router.parseUrl(`/create/${stepToNavigate.routeName}`);
    }

    this.stepService.index = getStepOrderByRouteName(routeName);
    return true;
  }
}
