import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { fadeInOutAnimation } from './../../shared/animations';
import { CODE_LENGTH, CODE_TYPE, ERROR_CODES, LoadingStatus, MODE_OF_VERIFICATION } from './../../shared/interfaces/common';
import { MfaCodeFormGroup } from '../../shared/interfaces/forms';
import { PlistaApiService } from './../../shared/plista-api.service';
import { AUTH_CODE } from './../../shared/validation-pattern';

@Component({
  selector: 'lo-mfa-code',
  templateUrl: './mfa-code.component.html',
  styleUrls: ['./mfa-code.component.scss'],
  animations: [fadeInOutAnimation]
})
export class MfaCodeComponent implements AfterViewInit {
  @Input() id: string;
  @Output() onConfirm = new EventEmitter();
  @Output() onUseAlternative = new EventEmitter<MODE_OF_VERIFICATION>();
  @Output() onTokenExpired = new EventEmitter();
  @ViewChild('codeField') codeFieldElement: ElementRef;

  submitStatus: LoadingStatus;
  form: FormGroup<MfaCodeFormGroup>;
  errorMessageKey: string;
  LoadingStatus = LoadingStatus;
  codeLength: CODE_LENGTH = CODE_LENGTH.AUTH_CODE;

  constructor(private api: PlistaApiService, private fb: FormBuilder) {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.codeFieldElement.nativeElement.focus();
  }

  private initForm() {
    this.form = this.fb.group({
      code: ['', [Validators.required, Validators.pattern(AUTH_CODE)]]
    });

    this.form
      .get('code')
      .valueChanges.pipe(debounceTime(100))
      .subscribe((codeValue: string) => {
        this.submitStatus = LoadingStatus.DEFAULT;
        this.errorMessageKey = null;
        /**auto submit on valid code */
        if (codeValue.length === this.codeLength && this.form.valid) {
          this.confirmCode();
        }
      });
  }

  confirmCode() {
    this.submitStatus = LoadingStatus.LOADING;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.api.challenge2Fa(this.form.get('code').value, CODE_TYPE.AUTH_CODE).subscribe(
      () => {
        this.onConfirm.emit();
      },
      error => {
        const errorCode = error?.['error']?.['errorCode'];

        if ((errorCode as ERROR_CODES) === ERROR_CODES.EXPIRED_2FA_TOKEN) {
          this.onTokenExpired.emit();
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (Object.values(ERROR_CODES).includes(errorCode)) {
          this.errorMessageKey = `MFA_ERRORS.${errorCode}`;
        } else {
          this.errorMessageKey = `MFA_ERRORS.UNKNOWN`;
        }

        this.submitStatus = LoadingStatus.FAILED;
      }
    );
  }

  useAlternative() {
    this.onUseAlternative.emit(MODE_OF_VERIFICATION.RECOVERY_CODE);
  }
}
