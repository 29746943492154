import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getBrowserLanguage } from './language-toggle/language-toggle';
import { PlistaCookieService } from './shared/cookie.service';
import { getValidLanguage, setLanguage } from './shared/language';
import { NavigationHelper } from './shared/navigation';
import { RequestStatus } from './shared/registration-steps/step.service';
import { ToastService } from './toast/toast.service';

@Component({
  selector: 'lo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private titleService: Title,
    private cookieService: PlistaCookieService,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) {
    this.titleService.setTitle('Login | plista');
    const lang = getValidLanguage(this.cookieService.getCookies('language_preference'), getBrowserLanguage());
    setLanguage(this.translate, lang);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      if (param.verified === 'true') {
        this.toastService.open('ACCOUNT_VERIFICATION.VERIFIED', RequestStatus.SUCCESS);
      } else if (param.verified === 'false') {
        this.toastService.open('ACCOUNT_VERIFICATION.NOT_VERIFIED', RequestStatus.FAILED, false, { link: NavigationHelper.getAccountVerificationUrl() });
      }
    });
  }
}
