export const environment = {
  production: false,
  name: 'local',
  baseUrl: '',
  bookingUrl: 'http://localhost:4200',
  statisticsUrl: 'http://localhost:4400',
  apiUrl: 'http://localhost:4001',
  planetUrl: 'http://localhost:4600',
  recaptchaKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  secure: false,
  env: 'local',
  sentryKey: 'https://db0c0a0d27db425b83cc9d6ce2c83de3@o541129.ingest.sentry.io/5735907',
  version: 'VERSION'
};
