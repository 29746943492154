<div class="page">
  <div class="page__content">
    <div class="content-subtitle">{{"CREATE.PASSWORD.SUB_TITLE" | translate}}</div>
    <div class="content-title">{{"CREATE.PASSWORD.TITLE" | translate}}</div>

    <form class="content-form" [formGroup]="form" (ngSubmit)="executeCaptcha()">
      <!-- input: password -->
      <div class="input-wrap input-wrap--password">
        <label class="input-label" for="create_input-password">{{ 'CREATE.PASSWORD.LABEL' | translate }}</label>
        <i *ngIf="!showPassword" (click)="showPasswordClicked(true)" class="fal fa-eye-slash password-input-eye"></i>
        <i *ngIf="showPassword" (click)="showPasswordClicked(false)" class="fal fa-eye password-input-eye"></i>
        <input
          (focus)="passwordFieldFocused = true"
          (blur)="passwordFieldFocused = false"
          class="input"
          type="{{ passwordInputType }}"
          formControlName="password"
          id="create_input-password"
          [ngClass]="{
          'input--invalid':
          (form.get('password').touched &&
          form.get('password').hasError('required')) ||
            (!form.get('password').hasError('required') &&
               form.get('password').hasError('minLength'))
        }"
        />

        <ul class="validation-hint-list margin-bottom-30">
          <li class="validation-hint-list__item" [class.invalid]="form.get('password').hasError('minLength')">
            <span>{{ 'CREATE.PASSWORD.HINT.LENGTH' | translate }}</span>
          </li>
        </ul>
      </div>

      <!-- ./input: password -->

      <div class="legal" innerHtml="{{'CREATE.PASSWORD.CONDITIONS' | translate: {termsAndConditionsLink: legalLinks.termsAndConditions, privacyLink: legalLinks.privacy} }}"></div>

      <!-- Next Button -->
      <button class="button button--primary button--fluid m-t-12" [disabled]="!form.valid || processing" type="submit" id="step7-next">
        <span class="spinner-container" *ngIf="processing">
          <i class="fal fa-spinner fa-spin"></i>
        </span>
        <span>{{ 'CREATE.NEXT' | translate }}</span>
        <i class="fal fa-chevron-right"></i>
      </button>
      <!-- ./Next Button -->
      <re-captcha size="invisible" #captchaRef="reCaptcha" style="display: none" (resolved)="register($event)"></re-captcha>
    </form>
  </div>
  <div class="page__graphics">
    <div class="image-wrapper">
      <img src="/assets/images/registration/password.svg" />
    </div>
  </div>
</div>
