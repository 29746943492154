<div class="page">
  <div class="page__content">
    <div class="content-title" *ngIf="!isUserInvited">{{'CREATE.COMPANY_NAME.TITLE'| translate}}</div>
    <div class="content-title" *ngIf="isUserInvited">{{'CREATE.COMPANY_NAME.TITLE_INVITED_USER'| translate}}</div>

    <div class="content-subtitle" *ngIf="!isUserInvited">{{'CREATE.COMPANY_NAME.SUBTITLE'| translate}}</div>
    <form class="content-form" [formGroup]="form">
      <div class="input-wrap">
        <!-- company name -->
        <label class="input-label" *ngIf="!isUserInvited" for="create_input-company-name">{{ 'CREATE.COMPANY_NAME.LABEL' | translate }}</label>
        <label class="input-label" *ngIf="isUserInvited" for="create_input-company-name">{{ 'CREATE.COMPANY_NAME.TITLE_INVITED_USER' | translate }}</label>

        <input
          type="text"
          class="input"
          [@fadeInOutAnimation]
          autocomplete="off"
          formControlName="companyName"
          id="create_input-company-name"
          [ngClass]="{ 'input--invalid': form.get('companyName').touched && form.get('companyName').invalid }"
          maxlength="220"
        />

        <!-- <span *ngIf="isUserInvited" class="info">{{'CREATE.COMPANY_NAME.INFO_INVITATION' | translate}}</span> -->
        <span *ngIf="form.get('companyName').touched && form.get('companyName').errors?.required" [@fadeInOutAnimation] class="form_error">
          {{ 'CREATE.COMPANY_NAME.ERROR' | translate }}
        </span>

        <span *ngIf="form.get('companyName').touched  && form.get('companyName').errors?.isStringValid " [@fadeInOutAnimation] class="form_error">
          {{ 'ERRORS.INVALID_STRING' | translate }}
        </span>
        <!-- ./company name -->
      </div>
      <!-- Next Button -->

      <button
        (click)="next(REGISTRATION_STEP_NAME.COMPANY_NAME, form.value)"
        class="button button--primary button--fluid m-t-12"
        type="button"
        id="step4-next"
        [disabled]="!isFormValid()"
      >
        <span>{{ 'CREATE.NEXT' | translate }}</span>
        <i class="fal fa-chevron-right"></i>
      </button>
      <!-- ./Next Button -->
    </form>
  </div>
  <div class="page__graphics">
    <div class="image-wrapper">
      <img src="/assets/images/registration/businessName.svg" />
    </div>
  </div>
</div>
