<div class="mfa-content">
  <div class="mfa-content__headline">{{"MFA_SETUP.SETUP_INFO.TITLE" | translate}}</div>
  <div class="m-t-24 mfa-content__description">{{"MFA_SETUP.SETUP_INFO.DESCRIPTION_1" | translate}}</div>
  <div class="m-t-24 mfa-content__description">{{"MFA_SETUP.SETUP_INFO.DESCRIPTION_2" | translate}}</div>

  <button id="{{id}}-setup-button" class="setup-button m-t-24 button button--primary button--fluid m-b-24" (click)="setupMfa()">
    <span>{{"MFA_SETUP.SETUP_INFO.SETUP" | translate}}</span>
    <i class="fal fa-chevron-right"></i>
  </button>
</div>
