/* core */
import { Injectable } from '@angular/core';
/* 3rd party */
import { Subject } from 'rxjs';
import { PlistaApiService } from './../shared/plista-api.service';

@Injectable()
export class HelpModalService {
  /**
   * Used to emit events to the component
   * accepts:
   * status -> boolean || string, to open and close the modal and also send 'error' & 'submitted' states
   * area -> string, to indicate the area of the planet i.e. 'advertisers' || 'publishers'
   */
  public isModalActive = new Subject();

  constructor(private apiService: PlistaApiService) {}

  /**
   * can be used from other components that need to trigger the modal.
   */
  public toggleHelpModalForm(status: boolean) {
    this.isModalActive.next(status);
  }

  public getDomain(type?: string) {
    if (type === 'full') {
      const hostArr = window.location.hostname.split('.');
      const apiHost = hostArr[0].split('-');
      apiHost[0] = 'api';
      hostArr[0] = apiHost.join('-');
      const apiHostUrl = window.location.protocol + '//' + hostArr.join('.');

      return apiHostUrl;
    }

    return document.domain.split('.')[0];
  }

  /**
   * Get cookie information out of specific key (cname)
   */
  public getCookieInfo(cname) {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  /**
   * Get the tenant
   */
  protected getTenantInfo() {
    return window.location.pathname.split('/')[1];
  }

  /**
   * Get the Browser and it's version
   */
  public getBrowser() {
    if (navigator.userAgent) {
      const ua = navigator.userAgent;
      let temp: RegExpMatchArray;
      let m: RegExpMatchArray = ua.match(/(opera|chrome|safari|firefox|msie|trident|edgeHtml|edge(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(m[1])) {
        temp = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (temp[1] || '');
      }
      if (m[1] === 'Chrome') {
        temp = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (temp !== null) {
          return temp.slice(1).join(' ').replace('OPR', 'Opera');
        }
      }
      m = m[2] ? [m[1], m[2]] : [navigator.appName, navigator.appVersion, '-?'];
      // eslint-disable-next-line no-cond-assign
      if ((temp = ua.match(/version\/(\d+)/i)) !== null) {
        m.splice(1, 1, temp[1]);
      }
      return m.join(' ');
    }

    return 'Some different Browser';
  }

  /**
   * Get OS Information, on Windows there will be the Kernel Version
   * and the Platform it's running on (32bit or 64bit) otherwise there is only the
   * Platform
   */
  public getOS() {
    if (window.navigator.platform) {
      return window.navigator.platform;
    }

    return 'Not known operating system';
  }

  /**
   * Are cookies enabled
   */
  public getCookieEnabled() {
    if (navigator.cookieEnabled) {
      return navigator.cookieEnabled;
    }

    return 'Could not get cookie information';
  }

  /**
   * Get inner Browser Resolution and the Resolution of the screen
   */
  public getScreenInfo() {
    if (window) {
      const screenInfo = `Browserwindowresolution: ${window.screen.availWidth}x${window.screen.availHeight}, Screenresolution: ${window.screen.width}x${window.screen.height}`;
      return screenInfo;
    }

    return 'Could not get Screeninformation';
  }

  /**
   * Check if CORS is available by Browser
   * and check if it's a MS Browser with different Specs
   * to enable CORS working in all Major Browsers
   */
  protected createCORSRequest(method: string, url: string) {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    return xhr;
  }

  /**
   * Send the Message and update the Send Button for validation of success or failure
   */
  public sendData(msg) {
    this.apiService.sendMessage(msg).subscribe(
      () => {
        this.isModalActive.next({ status: 'submitted', area: null });
      },
      () => {
        this.isModalActive.next({ status: 'error', area: null });
      }
    );
  }
}
