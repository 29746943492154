import { AbstractControl } from '@angular/forms';
import { COUNTRY_LIST, Country, countriesExcludedFromRegistration } from './country-list';

export interface BusinessLocation {
  transKey: string;
  nameDE: string;
  nameEN: string;
}

export class UtilsService {
  static trimSpaces(formControl: AbstractControl<string>) {
    if (formControl.value) {
      formControl.setValue(formControl.value.trim());
    }
  }

  static getBusinessLocations(): BusinessLocation[] {
    return COUNTRY_LIST.filter((country: Country): boolean => !countriesExcludedFromRegistration.includes(country.urlkey)).map((country: Country): BusinessLocation => {
      return {
        nameEN: country.nameEN,
        nameDE: country.nameDE,
        transKey: country.urlkey.toUpperCase()
      };
    });
  }

  static getCountryList(): Country[] {
    return COUNTRY_LIST.filter((country: Country): boolean => !countriesExcludedFromRegistration.includes(country.urlkey));
  }

  static hideAppLoader() {
    if (document.querySelector('.loading')) {
      document.body.removeChild(document.querySelector('.loading'));
    }
  }

  static isOriginUrlValid(url: string): boolean {
    const regex = /^(https:\/\/)[a-z0-9-]+([\-\.]plista+)(\.com)([a-zA-Z0-9-\/?=&])*$/;
    return regex.test(url);
  }
}
