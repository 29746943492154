<div class="page">
  <div class="page__content">
    <div class="content-title">{{'CREATE.MARKETING_OBJECTIVE.TITLE'| translate}}</div>
    <form class="content-form" [formGroup]="form">
      <div class="input-wrap">
        <!-- marketing objective -->
        <lo-custom-select
          [id]="'marketing-objective'"
          [list]="marketingObjectives"
          [initialSelectedItem]="initialSelectedValue"
          [translations]="translationsSelectField"
          (onValueChange)="onMarketingObjectiveSelect($event)"
        ></lo-custom-select>
        <!-- ./marketing objective -->
      </div>
      <!-- Next Button -->
      <button
        (click)="next(REGISTRATION_STEP_NAME.MARKETING_OBJECTIVE, form.value)"
        class="button button--primary button--fluid m-t-12"
        type="button"
        id="step6-next"
        [disabled]="!isFormValid()"
      >
        <span>{{ 'CREATE.NEXT' | translate }}</span>
        <i class="fal fa-chevron-right"></i>
      </button>
      <!-- ./Next Button -->
    </form>
  </div>
  <div class="page__graphics">
    <div class="image-wrapper">
      <img src="/assets/images/registration/marketing-objective.svg" />
    </div>
  </div>
</div>
