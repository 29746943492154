<div class="wrapper">
  <!-- FORGOT PASSWORD FORM SECTION -->
  <div class="wrapper__form" *ngIf="visiblePage === PAGE_VIEW_TYPES.FORM_PAGE">
    <div class="left">
      <form [formGroup]="form" class="block">
        <div class="headline">{{ 'FORGOT_PASSWORD.FORM.HEADLINE' | translate }}</div>
        <div class="subline" innerHTML="{{ 'FORGOT_PASSWORD.FORM.SUBLINE' | translate }}"></div>
        <div class="input-wrap m-b-4">
          <label class="input-label" for="reset_input-email">{{ 'FORGOT_PASSWORD.FORM.EMAIL.LABEL' | translate }}</label>
          <input class="input" id="reset_input-email" type="text" [@fadeInOutAnimation] formControlName="email" [ngClass]="{ 'input--invalid': submitAttempt && !email.valid }" />
          <span *ngIf="submitAttempt && !email.valid" [@fadeInOutAnimation] class="form_error">{{ 'LOGIN.EMAIL.ERROR' | translate }}</span>
        </div>

        <p class="ta-center subbody subbody--light m-b-0">
          <button class="button resetBtn_password button--primary button--fluid m-t-24" (click)="submit()" [disabled]="processing || !email.valid" id="reset_button-reset-password">
            <span class="spinner-container" *ngIf="processing">
              <i class="fal fa-spinner fa-spin"></i>
            </span>
            <ng-container *ngIf="!processing">{{ 'FORGOT_PASSWORD.FORM.SUBMIT' | translate }}</ng-container>
            <ng-container *ngIf="processing">{{ 'FORGOT_PASSWORD.FORM.SUBMIT_PROCESSING' | translate }}</ng-container>
          </button>

          <re-captcha size="invisible" #captchaRef="reCaptcha" style="display: none" (resolved)="sendData($event)"></re-captcha>
        </p>
        <p *ngIf="errorMessage" class="ta-center subbody subbody--light error_message">{{ errorMessage }}</p>
      </form>
    </div>
    <div class="right">
      <img class="image" src="assets/images/forgot_password_screen.svg" />
    </div>
  </div>

  <!-- FORGOT PASSWORD SUCCESS SECTION -->
  <div class="wrapper__success" *ngIf="visiblePage === PAGE_VIEW_TYPES.SUCCESS_PAGE">
    <div class="content">
      <div class="headline">{{ 'FORGOT_PASSWORD.SUCCESS.HEADLINE' | translate }}</div>
      <div class="subline">{{ 'FORGOT_PASSWORD.SUCCESS.SUBLINE' | translate }}</div>
      <div class="email">{{ email.value }}</div>
      <div class="action-message">{{ 'FORGOT_PASSWORD.SUCCESS.ACTION_MESSAGE' | translate }}</div>
      <div class="content-box">
        <div>{{ 'FORGOT_PASSWORD.SUCCESS.RESEND_BOX.HEADLINE' | translate }}</div>
        <div>
          {{ 'FORGOT_PASSWORD.SUCCESS.RESEND_BOX.SUBLINE' | translate }}
          <a class="link" (click)="resend()">{{ 'FORGOT_PASSWORD.SUCCESS.RESEND_BOX.LINK' | translate }}</a>
        </div>
      </div>
    </div>
  </div>

  <!-- FORGOT PASSWORD ERROR SECTION -->
  <div class="wrapper__error" *ngIf="visiblePage === PAGE_VIEW_TYPES.ERROR_PAGE">
    <div class="left">
      <div class="block">
        <div class="headline">{{ 'FORGOT_PASSWORD.ERROR.HEADLINE' | translate }}</div>
        <div class="subline" innerHTML="{{ 'FORGOT_PASSWORD.ERROR.SUBLINE' | translate: { email: email.value } }}"></div>
        <div class="content-box">
          <div innerHTML="{{ 'FORGOT_PASSWORD.ERROR.HELP_INFO.CONTENT' | translate }}"></div>
        </div>
      </div>
    </div>
    <div class="right"><img class="image" src="assets/images/bug_pic.svg" /></div>
  </div>
</div>
