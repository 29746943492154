import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { SearchDropDownTranslations } from '../search-dropdown/search-dropdown';
import { fadeInOutAnimation } from '../shared/animations';

@Component({
  selector: 'lo-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  animations: [fadeInOutAnimation]
})
export class CustomSelectComponent {
  @Input() id: string;
  @Input() initialSelectedItem: string;
  @Input() list: any[];
  @Input() translations: SearchDropDownTranslations;
  @Input() dropdownListSize: number;
  @Output() onValueChange: EventEmitter<string> = new EventEmitter<string>();

  public showListItems: boolean;
  public selectedItem: string;

  constructor(private elmentRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    if (this.showListItems) {
      const clickedInside = this.elmentRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.onFocusOut();
      }
    }
  }

  showList(): void {
    this.showListItems = !this.showListItems;
  }

  onFocusOut(): void {
    this.showListItems = false;
  }

  onitemSelect(value: string): void {
    this.initialSelectedItem = value;
    this.onValueChange.emit(value);
    this.showListItems = false;
  }
}
