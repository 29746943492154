import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeInOutAnimationSlow } from '../../../shared/animations';
@Component({
  selector: 'lo-personal-information-graphics',
  templateUrl: './personal-information-graphics.component.html',
  styleUrls: ['./personal-information-graphics.component.scss'],
  animations: [fadeInOutAnimationSlow]
})
export class PersonalInformationGraphicsComponent implements OnInit, OnDestroy {
  private animationInterval: ReturnType<typeof setTimeout>;
  public currentAnimationIndex = 0;

  ngOnInit(): void {
    this.startAnimation();
  }

  private startAnimation() {
    this.animationInterval = setInterval(() => {
      this.currentAnimationIndex = this.currentAnimationIndex === 2 ? 0 : this.currentAnimationIndex + 1;
    }, 2000);
  }

  ngOnDestroy(): void {
    if (this.animationInterval) {
      clearInterval(this.animationInterval);
    }
  }
}
