<div class="mfa-content">
  <div class="mfa-content__headline m-t-24">{{"MFA_VERIFICATION.MFA_RECOVERY.TITLE" | translate}}</div>
  <div class="mfa-content__description m-t-24">{{"MFA_VERIFICATION.MFA_RECOVERY.DESCRIPTION" | translate}}</div>

  <form class="mfa-content__form m-t-24" [formGroup]="form" (ngSubmit)="confirmCode()">
    <div class="input-wrap m-b-4">
      <label class="input-label" for="code">{{"MFA_VERIFICATION.MFA_RECOVERY.INPUT_LABEL" | translate}}</label>
      <input class="input" formControlName="code" [maxlength]="codeLength" autocomplete="off" [ngClass]="{'input--invalid' : submitStatus === LoadingStatus.FAILED}" />
      <!-- errors -->
      <span [@fadeInOutAnimation] class="input-error">
        <span *ngIf="form.get('code').hasError('pattern')" id="{{id}}-input-error">{{ 'MFA_VERIFICATION.MFA_RECOVERY.INVALID_PATTERN' | translate }}</span>
        <span *ngIf="submitStatus === LoadingStatus.FAILED" id="{{id}}-code-error">{{ errorMessageKey | translate }}</span>
      </span>
      <!-- ./errors -->
    </div>

    <a class="recovery-link m-t-24" (click)="useAlternative()">{{ 'MFA_VERIFICATION.MFA_RECOVERY.ALTERNATIVE' | translate }}</a>

    <button [disabled]="!form.valid || submitStatus === LoadingStatus.LOADING" class="m-t-24 button button--primary button--fluid m-b-24" type="submit">
      <span class="spinner-container" *ngIf="submitStatus === LoadingStatus.LOADING">
        <i class="fal fa-spinner fa-spin"></i>
      </span>
      <span>{{"MFA_VERIFICATION.MFA_RECOVERY.CONFIRM" | translate}}</span>
    </button>
  </form>

  <div class="content-box">
    <div innerHTML="{{ 'MFA_VERIFICATION.MFA_RECOVERY.NOTICE' | translate }}"></div>
  </div>
</div>
