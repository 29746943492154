import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { environment } from './../../environments/environment.local';
import { Injectable } from '@angular/core';

@Injectable()
export class PlistaCookieService {
  private cookieOptions: CookieOptions;
  constructor(private cookieService: CookieService) {}

  setCookies(key: string, value: string) {
    const hostArr = window.location.hostname.split('.');
    const cookieURL = environment.name === 'test' ? 'localhost' : '.' + hostArr.slice(hostArr.length - 2).join('.');
    const timestamp = new Date().getTime();

    this.cookieOptions = { domain: cookieURL, secure: environment.secure, path: '/', expires: new Date(timestamp + 60 * 60 * 24 * 1000) };
    this.cookieService.set(key, value, this.cookieOptions);
  }

  getCookies(key: string) {
    return this.cookieService.get(key);
  }
}
