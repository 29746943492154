import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { STORAGE_KEY } from '../constants';
import { StorageHelper } from '../helper/storage.helper';
import { REGISTRATION_STEPS, REGISTRATION_STEP_NAME, REGISTRATION_STEP_ROUTE_NAME } from './step';

export interface StepData {
  personalInformation?: {
    email: string;
    firstname: string;
    lastname: string;
  };
  businessLocation?: {
    tenant: string;
    name: string;
    city?: string;
  };
  businessType?: {
    businessType: string;
    otherBusinessType?: string;
  };
  companyName?: {
    companyName: string;
  };
  userRole?: {
    role: string;
    otherRole?: string;
  };
}

export enum RequestStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

export interface RegistrationData {
  email?: string;
  status: RequestStatus;
}

@Injectable()
export class StepService {
  private _index = 0;
  private currentStep = new BehaviorSubject(0);
  private businessLocation = new BehaviorSubject(null);
  private _stepData: StepData;
  private _registrationData: RegistrationData;

  constructor(private router: Router) {
    const storedStepData = StorageHelper.get(STORAGE_KEY);
    this._stepData = storedStepData || null;

    if (this.getStepDataByName(REGISTRATION_STEP_NAME.BUSINESS_LOCATION)?.tenant) {
      this.businessLocation.next(this.getStepDataByName(REGISTRATION_STEP_NAME.BUSINESS_LOCATION)?.tenant);
    }
  }

  get currentStepIndex(): BehaviorSubject<number> {
    return this.currentStep;
  }

  getBusinessLocationSubject() {
    return this.businessLocation;
  }

  emitBusinessLocation(tenant) {
    this.businessLocation.next(tenant);
  }

  next() {
    if (this.index + 1 < REGISTRATION_STEPS.length) {
      this.index = this.index + 1;
      const nextStep = REGISTRATION_STEPS[this.index];
      void this.router.navigate([`/create/${nextStep.routeName}`], { queryParamsHandling: 'merge' });
    }
  }

  prev() {
    if (this.index - 1 >= 0) {
      this.index = this.index - 1;
      const nextStep = REGISTRATION_STEPS[this.index];
      void this.router.navigate([`/create/${nextStep.routeName}`], { queryParamsHandling: 'merge' });
    }
  }

  save(stepName: string, formValue: any) {
    if (!this._stepData) {
      this._stepData = {
        [stepName]: formValue
      };
    } else {
      this._stepData[stepName] = formValue;
    }
  }

  getStepDataByName(stepName: string) {
    return this._stepData?.[stepName];
  }

  get stepData() {
    return this._stepData;
  }

  clear() {
    this.clearStepData();
    this.index = 0;
    this._registrationData = null;
  }

  clearStepData() {
    this._stepData = null;
  }

  isStepValid(fields, fieldValues) {
    const invalidFields = fields.filter(fieldName => !fieldValues[fieldName]);
    return !!invalidFields.length;
  }

  isValid(routeName: REGISTRATION_STEP_ROUTE_NAME): boolean {
    const invalidSteps = this.getInvalidSteps(routeName);
    if (invalidSteps.length > 0) {
      return false;
    }

    return true;
  }

  getInvalidSteps(routeName: REGISTRATION_STEP_ROUTE_NAME) {
    const targetStepIndex = REGISTRATION_STEPS.findIndex(item => item.routeName === routeName);
    const stepsToCheck = REGISTRATION_STEPS.slice(0, targetStepIndex);
    const invalidSteps = [];

    if (stepsToCheck.length) {
      stepsToCheck.forEach(step => {
        if (!this._stepData || !this._stepData[step.stepName]) {
          invalidSteps.push(step);
        } else if (this.isStepValid(step.fields, this._stepData[step.stepName])) {
          invalidSteps.push(step);
        }
      });
    }
    return invalidSteps;
  }

  set index(stepIndex: number) {
    this._index = stepIndex;
    this.currentStep.next(this._index);
  }

  get index(): number {
    return this._index;
  }

  public onSuccessfulRegistration() {
    this._registrationData = {
      email: this.stepData.personalInformation.email,
      status: RequestStatus.SUCCESS
    };

    this.clearStepData();
    this.next();
  }

  public onFailedRegistration() {
    this.clearStepData();
    this._registrationData = {
      status: RequestStatus.FAILED
    };
    void this.router.navigate([`/create/${REGISTRATION_STEP_ROUTE_NAME.ERROR}`]);
  }

  get registrationData(): RegistrationData {
    return this._registrationData;
  }
}
