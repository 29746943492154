import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { fadeInOutAnimation, routeAnimation } from '../../shared/animations';
import { StepService } from '../../shared/registration-steps/step.service';
import { excludeInternalUsers, isEmailTaken, isSimpleStringValid } from '../../shared/validation-methods';
import { EMAIL_REGEXP } from '../../shared/validation-pattern';
import { PersonalInformationFormGroup } from '../../shared/interfaces/forms';
import { PlistaApiService } from './../../shared/plista-api.service';
import { StepComponent } from './../../shared/registration-steps/step.directive';
import { UtilsService } from './../../shared/utils.service';
@Component({
  selector: 'lo-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss'],
  animations: [routeAnimation, fadeInOutAnimation]
})
export class PersonalInformationComponent extends StepComponent implements OnInit, OnDestroy {
  public form: FormGroup<PersonalInformationFormGroup>;
  public queryParams;
  public isUserInvited: boolean;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, public stepService: StepService, private plistaApi: PlistaApiService) {
    super(stepService);

    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        this.queryParams = params;
        if (params.exist === '0') {
          this.isUserInvited = true;
        }
      })
    );
  }

  ngOnInit(): void {
    this.initForm(this.stepService.getStepDataByName('personalInformation'));
  }

  private initForm(stepData) {
    this.form = this.fb.group({
      firstname: [stepData?.firstname || this.queryParams?.firstname || '', [Validators.required, isSimpleStringValid]],
      lastname: [stepData?.lastname || this.queryParams?.lastname || '', [Validators.required, isSimpleStringValid]],
      email: [
        stepData?.email || this.queryParams?.email || '',
        [Validators.required, Validators.pattern(EMAIL_REGEXP), excludeInternalUsers],
        isEmailTaken.bind({ plistaApi: this.plistaApi })
      ]
    });

    if (this.isUserInvited) {
      this.form.controls.email.disable();
    }
  }

  trimSpaces(formControl: AbstractControl<string>) {
    UtilsService.trimSpaces(formControl);
  }
}
