import { DEFAULT_TENANT } from './../constants/application.constants';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getLegalLinks, LegalLink } from '../app.links';
import { fadeInOutAnimation, routerSliderAnimation } from '../shared/animations';
import { REGISTRATION_STEPS } from '../shared/registration-steps/step';
import { StepService } from '../shared/registration-steps/step.service';
import { Unsubscriber } from '../shared/unsubscriber';
import { UtilsService } from '../shared/utils.service';

@Component({
  selector: 'lo-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
  animations: [routerSliderAnimation, fadeInOutAnimation]
})
export class CreateUserComponent extends Unsubscriber implements OnInit, OnDestroy {
  public lang: string;
  public progress = 0;
  public currentStepIndex: number;
  public translations;
  public numOfSteps = REGISTRATION_STEPS.length;
  public isBackBtnVisible;
  public legalLink: LegalLink;
  public businessLocation;

  constructor(private titleService: Title, private translate: TranslateService, private stepService: StepService) {
    super();
    this.lang = this.translate.getDefaultLang();
    this.titleService.setTitle('Register | plista');
  }

  ngOnInit(): void {
    this.subscriptions.push(this.translate.stream('CREATE').subscribe(trans => (this.translations = trans)));

    this.subscriptions.push(
      this.stepService.currentStepIndex.subscribe(index => {
        this.currentStepIndex = index;
        this.progress = this.getProgressPercentage(index, this.numOfSteps);
        this.isBackBtnVisible = this.currentStepIndex > 0 && this.currentStepIndex < 7;
      })
    );

    this.subscriptions.push(
      this.translate.onLangChange.subscribe(() => {
        this.setLegalLinks();
      })
    );

    this.subscriptions.push(
      this.stepService.getBusinessLocationSubject().subscribe(tenant => {
        setTimeout(() => {
          this.businessLocation = tenant;
          this.setLegalLinks();
        }, 0);
      })
    );

    UtilsService.hideAppLoader();
  }

  private setLegalLinks(): void {
    const tenant: string = this.businessLocation || DEFAULT_TENANT;
    this.legalLink = getLegalLinks(this.translate.currentLang, tenant);
  }

  ngOnDestroy(): void {
    this.stepService.clear();
  }

  back(): void {
    this.stepService.prev();
  }

  private getProgressPercentage(index: number, numOfSteps: number): number {
    return Math.ceil(((index + 1) / numOfSteps) * 100);
  }

  prepareRouteTransition(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
