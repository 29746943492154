import { UtilsService } from './../shared/utils.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { HelpModalService } from '../helpmodal/helpmodal.service';
import { fadeInOutAnimation } from '../shared/animations';
import { setLanguage } from '../shared/language';
import { Store } from '../shared/store';
@Component({
  selector: 'lo-advertiser-invitation',
  templateUrl: './advertiser-invitation.component.html',
  styleUrls: ['./advertiser-invitation.component.scss'],
  animations: [fadeInOutAnimation]
})
export class AdvertiserInvitationComponent implements OnInit, OnDestroy {
  public firstname: string;
  public lastname: string;
  public invitedFromFirstname: string;
  public invitedFromLastname: string;
  public invitedFromFullName: string;
  public fullName: string;
  public advertiser;
  public lang: string;
  public userExists: boolean;
  public userEmail: string;
  public code: string;
  public codeRoute: string;
  private routeQueryParams$: Subscription;
  private invitedTenant: string;
  private invitedTo;
  constructor(private route: ActivatedRoute, private router: Router, private translate: TranslateService, private helpdeskService: HelpModalService) {
    this.lang = this.translate.getDefaultLang();
  }
  ngOnInit(): void {
    this.routeQueryParams$ = this.route.queryParams.subscribe(params => {
      if (params.code) {
        this.code = params.code;
        this.codeRoute = `/create?code=${this.code}`;
      }
      if (params.email) {
        this.userEmail = params.email;
        Store.email = params.email;
      }
      if (params.firstname) {
        this.firstname = params.firstname;
      }
      if (params.lastname) {
        this.lastname = params.lastname;
      }
      if (params.firstname && params.lastname) {
        Store.username = `${params.firstname} ${params.lastname}`;
        this.fullName = Store.username;
      }
      if (params.invitedFromFirstname) {
        this.invitedFromFirstname = params.invitedFromFirstname;
      }
      if (params.invitedFromLastname) {
        this.invitedFromLastname = params.invitedFromLastname;
      }
      if (params.tenant) {
        this.invitedTenant = params.tenant;
      }
      if (params.invitedTo) {
        this.invitedTo = params.invitedTo;
      }

      this.invitedFromFullName = `${params.invitedFromFirstname} ${params.invitedFromLastname}`;

      if (params.advertiser) {
        this.advertiser = params.advertiser;
      }
      if (params.exist) {
        if (params.exist === '1') {
          this.userExists = true;
        } else {
          this.userExists = false;
        }
      }
    });

    UtilsService.hideAppLoader();
  }
  ngOnDestroy(): void {
    if (this.routeQueryParams$) {
      this.routeQueryParams$.unsubscribe();
    }
  }

  onLanguageChange(lang): void {
    this.lang = lang;
    setLanguage(this.translate, this.lang);
  }

  public openHelpdesk(): void {
    this.helpdeskService.toggleHelpModalForm(true);
  }

  public goTo() {
    if (this.userExists) {
      void this.router.navigate(['/']);
    } else {
      void this.router.navigate(['/create'], {
        queryParams: {
          exist: this.userExists ? '1' : '0',
          email: this.userEmail,
          firstname: this.firstname,
          lastname: this.lastname,
          advertiser: this.advertiser,
          tenant: this.invitedTenant,
          invitedTo: this.invitedTo
        }
      });
    }
  }
}
