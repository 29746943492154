<div class="wrapper">
  <!-- ACCOUNT VERIFICATION FORM SECTION -->
  <ng-container *ngIf="visiblePage === PAGE_VIEW_TYPES.FORM_PAGE">
    <div class="wrapper__form">
      <form [formGroup]="form" class="block">
        <div class="headline">{{ 'ACCOUNT_VERIFICATION.FORM_PAGE.HEAD_LINE_1' | translate }}</div>
        <div class="subline">{{ 'ACCOUNT_VERIFICATION.FORM_PAGE.SUBLINE' | translate }}</div>
        <div class="input-wrap m-b-4">
          <label class="input-label" for="reset_input-email">{{ 'ACCOUNT_VERIFICATION.FORM_PAGE.INPUT.LABEL' | translate }}</label>
          <input class="input" id="reset_input-email" type="text" [@fadeInOutAnimation] formControlName="email" [ngClass]="{ 'input--invalid': submitAttempt && !email.valid }" />
          <span *ngIf="submitAttempt && !email.valid" [@fadeInOutAnimation] class="form_error">{{ 'LOGIN.EMAIL.ERROR' | translate }}</span>
        </div>

        <p class="ta-center subbody subbody--light m-b-0">
          <button class="button resetBtn_password button--primary button--fluid m-t-24" (click)="resend()" [disabled]="processing || !email.valid" id="reset_button-reset-password">
            <span class="spinner-container" *ngIf="processing">
              <i class="fal fa-spinner fa-spin"></i>
            </span>
            <ng-container *ngIf="!processing">{{ 'ACCOUNT_VERIFICATION.FORM_PAGE.BUTTON' | translate }}</ng-container>
            <ng-container *ngIf="processing">{{ 'FORGOT_PASSWORD.FORM.SUBMIT_PROCESSING' | translate }}</ng-container>
          </button>
        </p>
        <p *ngIf="errorMessage" class="ta-center subbody subbody--light error_message">{{ errorMessage }}</p>
      </form>
    </div>
  </ng-container>

  <!-- ACCOUNT VERIFICATION SUCCESS SECTION -->
  <ng-container *ngIf="visiblePage === PAGE_VIEW_TYPES.SUCCESS_PAGE">
    <div class="wrapper__success">
      <div class="block">
        <div class="headline">{{ 'ACCOUNT_VERIFICATION.SUCCESS_PAGE.HEADLINE' | translate }}</div>
        <div class="subline">{{ 'ACCOUNT_VERIFICATION.SUCCESS_PAGE.SUBLINE' | translate }}</div>
        <div class="email">{{ email.value }}</div>
        <div class="content-box">
          <div>
            {{ 'ACCOUNT_VERIFICATION.SUCCESS_PAGE.INFO_BOX.PART_1' | translate }}
            <br />
            {{ 'ACCOUNT_VERIFICATION.SUCCESS_PAGE.INFO_BOX.PART_2' | translate }}

            <a class="link" (click)="resend()">{{ 'ACCOUNT_VERIFICATION.SUCCESS_PAGE.INFO_BOX.LINK' | translate }}</a>
            <span *ngIf="processing" class="spinner" [@fadeInOutAnimation]><i class="fad fa-spinner-third fa-spin"></i></span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- ACCOUNT VERIFICATION ERROR SECTION -->
  <ng-container *ngIf="visiblePage === PAGE_VIEW_TYPES.ERROR_PAGE">
    <div class="wrapper__error">
      <div class="content">
        <div class="headline">{{ 'ACCOUNT_VERIFICATION.ERROR_PAGE.HEADLINE' | translate }}</div>
        <div class="subline">{{ 'ACCOUNT_VERIFICATION.ERROR_PAGE.SUBLINE' | translate :{email: email.value} }}</div>

        <div class="content-box">
          <div innerHTML="{{ 'ACCOUNT_VERIFICATION.ERROR_PAGE.INFO_BOX.CONTENT' | translate }}"></div>
        </div>

        <button class="button button--primary button--fluid" (click)="resend()" [disabled]="processing" id="reset_button-resend-link">
          {{ 'RESET_PASSWORD.LINK_EXPIRED_PAGE.BUTTON' | translate }}
        </button>
      </div>
      <img class="image" src="assets/images/bug_pic.svg" />
    </div>
  </ng-container>
</div>
