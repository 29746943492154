import { environment } from '../../environments/environment';
import { DEFAULT_TENANT, VALID_PUB_TENANT } from '../constants/application.constants';

export class NavigationHelper {
  static statisticsUrl(tenant): string {
    return `${this.getAppUrl(environment.statisticsUrl, tenant)}/`;
  }

  static getPlanetUrl(tenant: string): string {
    const routeTenant = VALID_PUB_TENANT.includes(tenant) ? tenant : 'de';
    return `${environment.planetUrl}/${routeTenant}/dashboard`;
  }

  static bookingsUrl(tenant, isNewUser = false): string {
    return isNewUser ? `${this.getAppUrl(environment.bookingUrl, tenant)}?newUser=true` : `${this.getAppUrl(environment.bookingUrl, tenant)}`;
  }

  static getAppUrl(url: string, tenant): string {
    if (environment.name === 'production' || environment.name === 'stage') {
      return `${environment.baseUrl}/${tenant}/${url}`;
    }
    return url;
  }

  static getAccountVerificationUrl() {
    let url;
    if (environment.name === 'production') {
      url = `http://login.plista.com/account-verification`;
    } else if (environment.name === 'stage') {
      url = `http://login-test.plista.com/account-verification`;
    } else {
      url = `http://localhost:4500/account-verification`;
    }

    return url;
  }

  static getRecoveryCodeDownloadUrl(): string {
    return `${environment.apiUrl}/${DEFAULT_TENANT}/auth/mfa/recovery-codes/download`;
  }
}
