import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { sortByProperty } from '../../../app/shared/sorting';
import { REGISTRATION_STEP_NAME } from '../../shared/registration-steps/step';
import { BusinessType, BUSINESS_TYPE_LIST, OTHER } from '../create-user';
import { fadeInOutAnimation, routeAnimation } from './../../shared/animations';
import { BusinessTypeFormGroup } from '../../shared/interfaces/forms';
import { StepComponent } from './../../shared/registration-steps/step.directive';
import { StepService } from './../../shared/registration-steps/step.service';
import { isSimpleStringValid } from './../../shared/validation-methods';
@Component({
  selector: 'lo-business-type',
  templateUrl: './business-type.component.html',
  styleUrls: ['./business-type.component.scss'],
  animations: [routeAnimation, fadeInOutAnimation]
})
export class BusinessTypeComponent extends StepComponent implements OnInit, OnDestroy {
  public form: FormGroup<BusinessTypeFormGroup>;
  public businessTypeList: BusinessType[] = BUSINESS_TYPE_LIST;
  public REGISTRATION_STEP_NAME = REGISTRATION_STEP_NAME;
  public translationsSearchDropdown;

  public showOtherInputField: boolean;
  public initialSelectedValue: string;
  public filterByProperty: string;

  constructor(private fb: FormBuilder, public stepService: StepService, private translate: TranslateService) {
    super(stepService);
  }

  ngOnInit(): void {
    this.subscriptions.push(this.translate.stream('CREATE.BUSINESS_TYPE').subscribe(trans => (this.translationsSearchDropdown = trans)));
    this.subscriptions.push(
      this.translate.onLangChange.subscribe(() => {
        this.filterByProperty = 'name' + this.translate.currentLang.toUpperCase();
      })
    );
    this.initForm(this.stepService.getStepDataByName(REGISTRATION_STEP_NAME.BUSINESS_TYPE));
  }

  private initForm(stepData): void {
    const selectedBusinessType: string = stepData?.businessType || '';
    this.filterByProperty = 'name' + this.translate.currentLang.toUpperCase();

    sortByProperty(this.businessTypeList, this.filterByProperty);
    this.businessTypeList.push(OTHER); // Add Other/Sonstige to the end of the Business Type list

    if (selectedBusinessType) {
      const initialBusinessType = BUSINESS_TYPE_LIST.filter((businessType: BusinessType): boolean => businessType.nameEN === selectedBusinessType);
      this.initialSelectedValue = this.translate.currentLang === 'en' ? initialBusinessType[0].nameEN : initialBusinessType[0].nameDE;
    }

    this.form = this.fb.group({
      businessType: [selectedBusinessType, Validators.required],
      otherBusinessType: ['']
    });
    this.showExtraInputField(selectedBusinessType, stepData?.otherBusinessType as string);
  }

  onSelectItem(event: BusinessType): void {
    this.form.get('businessType').setValue(event.nameEN);
    this.showExtraInputField(event.nameEN);
  }

  showExtraInputField(type: string, value: string = null): void {
    if (type === 'Other') {
      this.showOtherInputField = true;
      this.form.get('otherBusinessType').setValidators([Validators.required, isSimpleStringValid]);
      this.form.get('otherBusinessType').setValue(value);
    } else {
      this.showOtherInputField = false;
      this.form.get('otherBusinessType').clearValidators();
      this.form.get('otherBusinessType').setValue(null);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
