<div class="mfa-content">
  <div class="mfa-content__headline">{{"MFA_SETUP.APP_REGISTRATION.TITLE" | translate}}</div>
  <div class="mfa-content__description m-t-24" [innerHTML]="'MFA_SETUP.APP_REGISTRATION.DESCRIPTION_1' | translate : {code: qrCodeString}"></div>
  <div class="mfa-content__qrcode m-t-24">
    <div class="qrcode" [innerHTML]="qrCodeSvg"></div>
  </div>
  <div class="mfa-content__description m-t-24" [innerHTML]="'MFA_SETUP.APP_REGISTRATION.DESCRIPTION_2' | translate"></div>
  <!-- code input field -->
  <form class="mfa-content__form m-t-24" [formGroup]="form" (ngSubmit)="confirmCode()">
    <div class="input-wrap m-b-4">
      <label class="input-label" for="code">{{"MFA_SETUP.APP_REGISTRATION.INPUT_LABEL" | translate}}</label>
      <input
        id="code"
        #setUpCodeField
        class="input"
        formControlName="code"
        [maxlength]="codeLength"
        autocomplete="off"
        [ngClass]="{'input--invalid' : submitStatus === LoadingStatus.FAILED}"
      />

      <!-- error block -->
      <span [@fadeInOutAnimation] class="input-error">
        <span *ngIf="form.get('code').hasError('pattern')" id="{{id}}-invalid-pattern">{{ 'MFA_VERIFICATION.MFA_CODE.INVALID_PATTERN' | translate }}</span>
        <span *ngIf="submitStatus === LoadingStatus.FAILED" id="{{id}}-invalid-code">{{ errorMessageKey | translate }}</span>
      </span>
      <!-- ./error block -->
    </div>
    <button id="{{id}}-confirm-qrcode" [disabled]="!form.valid || submitStatus === LoadingStatus.LOADING" class="m-t-24 button button--primary button--fluid m-b-24" type="submit">
      <span class="spinner-container" *ngIf="submitStatus === LoadingStatus.LOADING">
        <i class="fal fa-spinner fa-spin"></i>
      </span>
      <span>{{"MFA_SETUP.APP_REGISTRATION.CONFIRM" | translate}}</span>
    </button>
  </form>
</div>
