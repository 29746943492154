export class StorageHelper {
  static save(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  static get(key: string) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  static clear(key: string) {
    sessionStorage.removeItem(key);
  }
}
