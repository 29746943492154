export const environment = {
  production: true,
  name: 'production',
  baseUrl: 'https://selfservice.plista.com',
  bookingUrl: 'booking',
  statisticsUrl: 'statistics',
  apiUrl: 'https://api.plista.com',
  planetUrl: 'https://planet.plista.com',
  recaptchaKey: '6LdO26EfAAAAAG3U5qd1pNgpWJ5PWgfDaZ1lSGYd',
  secure: true,
  env: 'production',
  sentryKey: 'https://db0c0a0d27db425b83cc9d6ce2c83de3@o541129.ingest.sentry.io/5735907',
  version: '2bdee748cf58fa6e0403d9a021f5c9d663c2a4f1'
};
