import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PlistaCookieService } from '../shared/cookie.service';

@Component({
  selector: 'lo-language-toggle',
  templateUrl: './language-toggle.component.html',
  styleUrls: ['./language-toggle.component.scss']
})
export class LanguageToggleComponent implements OnInit {
  public lang: string;

  @Input() id: string;
  @Input() initialLanguage: string;
  @Output() onLanguageChange = new EventEmitter<string>();

  constructor(private translate: TranslateService, private cookieService: PlistaCookieService) {}

  ngOnInit(): void {
    this.lang = this.initialLanguage;
    this.translate.use(this.lang);
  }

  setLang(e): void {
    this.lang = e.target.checked ? `de` : `en`;
    this.translate.use(this.lang);
    this.cookieService.setCookies('language_preference', this.lang);
    this.onLanguageChange.emit(this.lang);
  }
}
