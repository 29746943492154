export const DEFAULT_TENANT = 'de';

export const NEW_TENANT = ['SE', 'DK', 'AU', 'IN'];

export const VALID_TENANT = ['DE', 'AT', 'CH', 'DK', 'SE', 'AU', 'IN'];

export const VALID_PUB_TENANT = [
  'at',
  'au',
  'be',
  'ca',
  'ch',
  'cn',
  'cz',
  'de',
  'dk',
  'es',
  'fi',
  'hk',
  'hr',
  'mena',
  'hk',
  'in',
  'it',
  'my',
  'nl',
  'nz',
  'no',
  'pl',
  'pt',
  'ru',
  'ro',
  'sg',
  'hu',
  'sk',
  'si',
  'se',
  'th',
  'tr',
  'tw'
];

export enum PAGE_VIEW_TYPES {
  FORM_PAGE = 'Form',
  ERROR_PAGE = 'Error',
  SUCCESS_PAGE = 'Success'
}

export enum INPUT_TYPE {
  PASSWORD = 'password',
  TEXT = 'text'
}

export const VALID_LANG_LIST: string[] = ['en', 'de'];
