export interface LegalLinks {
  [name: string]: {
    [name: string]: LegalLink;
  };
}

export interface LegalLink {
  termsAndConditions: string;
  privacy: string;
  imprint: string;
}

// TODO: Verfiy the links. For example: https://www.plista.com/se/privacy does not exist
export function getLegalLinks(language = 'en', tenant: string): LegalLink {
  const links: LegalLinks = {
    en: {
      de: {
        imprint: 'https://www.plista.com/imprint',
        termsAndConditions: 'https://www.plista.com/advertiser/advertiser-gtc',
        privacy: 'https://www.plista.com/about/privacy'
      },
      au: {
        imprint: 'https://www.plista.com/imprint',
        termsAndConditions: 'https://www.plista.com/advertiser/advertiser-gtc',
        privacy: 'https://www.plista.com/about/privacy'
      },
      se: {
        imprint: 'https://www.plista.com/imprint',
        termsAndConditions: 'https://www.plista.com/wp-content/uploads/2019/12/TC_Sweden_plista_SelfService_GroupM-addendum2019.pdf',
        privacy: 'https://www.plista.com/se/privacy'
      },
      pl: {
        imprint: 'https://www.plista.com/imprint',
        termsAndConditions: 'http://www.plista.com/wp-content/uploads/2019/12/TC_Poland_plista_SelfService_GroupM-addendum2019.pdf',
        privacy: 'https://www.plista.com/pl/privacy'
      },
      dk: {
        imprint: 'https://www.plista.com/imprint',
        termsAndConditions: 'https://www.plista.com/wp-content/uploads/2019/09/09.09.19_TC-Denmark.pdf',
        privacy: 'https://www.plista.com/dk/privacy'
      }
    },
    de: {
      de: {
        imprint: 'https://www.plista.com/de/impressum',
        termsAndConditions: 'https://www.plista.com/de/advertiser/advertiser-gtc',
        privacy: 'https://www.plista.com/de/ueber/privacy'
      },
      au: {
        imprint: 'https://www.plista.com/imprint',
        termsAndConditions: 'https://www.plista.com/advertiser/advertiser-gtc',
        privacy: 'https://www.plista.com/about/privacy'
      },
      se: {
        imprint: 'https://www.plista.com/de/impressum',
        termsAndConditions: 'https://www.plista.com/wp-content/uploads/2019/12/TC_Sweden_plista_SelfService_GroupM-addendum2019.pdf',
        privacy: 'https://www.plista.com/se/privacy'
      },
      pl: {
        imprint: 'https://www.plista.com/de/impressum',
        termsAndConditions: 'http://www.plista.com/wp-content/uploads/2019/12/TC_Poland_plista_SelfService_GroupM-addendum2019.pdf',
        privacy: 'https://www.plista.com/pl/privacy'
      },
      dk: {
        imprint: 'https://www.plista.com/de/impressum',
        termsAndConditions: 'https://www.plista.com/wp-content/uploads/2019/09/09.09.19_TC-Denmark.pdf',
        privacy: 'https://www.plista.com/dk/privacy'
      }
    }
  };

  return links[language][tenant] || links[language]['de'];
}
