import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import smartlookClient from 'smartlook-client';
import { SentryHelper } from './app/shared/sentry';

if (environment.production) {
  enableProdMode();
  smartlookClient.init('3951c8fe815ce628b21bda3d33a575626117e583');
}

if (environment.name === 'stage' || environment.name === 'production') {
  SentryHelper.init();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
