import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fadeInOutAnimation } from '../shared/animations';
import { setLanguage } from '../shared/language';
import { PasswordValidator } from '../shared/password.validator';
import { PlistaApiService } from '../shared/plista-api.service';
import { Store } from '../shared/store';
import { Unsubscriber } from '../shared/unsubscriber';
import { UtilsService } from '../shared/utils.service';
import { minLength } from '../shared/validation-methods';
import { INPUT_TYPE } from './../constants/application.constants';
import { PasswordResetFormGroup } from '../shared/interfaces/forms';

@Component({
  selector: 'lo-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  animations: [fadeInOutAnimation]
})
export class PasswordResetComponent extends Unsubscriber implements OnInit {
  form: FormGroup<PasswordResetFormGroup>;
  showForm: boolean;
  lang: string;
  passwordFieldFocused: boolean;
  showConfirmPassword: boolean;
  showNewPassword: boolean;
  submitAttempt = false;
  resendClicked = false;
  resetClicked = false;
  errorMessage: string;
  tenant;
  processing: boolean;
  resetSuccess = false;
  notAllowed: boolean;
  userEmail: string;
  hash: string;
  hashExpired: boolean;
  routeQueryParams$;

  showThis = true;

  public newPasswordInputType: INPUT_TYPE;
  public confirmPasswordInputType: INPUT_TYPE;

  constructor(private plistaApiService: PlistaApiService, private translate: TranslateService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder) {
    super();
    this.routeQueryParams$ = this.route.queryParams.subscribe(params => {
      this.tenant = document.location.pathname.split('/')[1];
      if (!params.hash) {
        this.notAllowed = true;
      }
      if (params.hash && params.email) {
        const body = { hash: params.hash, email: params.email };
        this.plistaApiService.isPasswordResetValid(body).subscribe(
          data => {
            if (data.status === true) {
              this.hash = params.hash;
              this.hashExpired = false;
            }
            if (data.status === false) {
              this.hash = params.hash;
              this.hashExpired = true;
            }
            if (data.status === 'invalid hash') {
              this.notAllowed = true;
            }
          },
          () => {
            this.errorMessage = 'Request failed - Something went wrong, please try again later.';
          }
        );
      }
      if (params.email) {
        this.userEmail = params.email;
      }
    });
    this.lang = this.translate.getDefaultLang();
  }

  ngOnInit(): void {
    this.initForm();
    this.showConfirmPassword = false;
    this.showNewPassword = false;
    this.confirmPasswordInputType = INPUT_TYPE.PASSWORD;
    this.newPasswordInputType = INPUT_TYPE.PASSWORD;
    UtilsService.hideAppLoader();
  }

  showNewPasswordClicked(val: boolean): void {
    this.showNewPassword = val;
    this.newPasswordInputType = val ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD;
  }

  showConfirmPasswordClicked(val: boolean): void {
    this.showConfirmPassword = val;
    this.confirmPasswordInputType = val ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD;
  }

  initForm(): void {
    this.form = this.fb.group(
      {
        password: ['', Validators.compose([Validators.required, minLength])],
        confirm_password: ['', Validators.required]
      },
      {
        validators: PasswordValidator.areEqual
      }
    );
    this.showForm = true;
  }

  onLanguageChange(lang): void {
    this.lang = lang;
    setLanguage(this.translate, this.lang);
  }
  resetPassword(): void {
    this.submitAttempt = true;
    if (this.form.valid) {
      this.processing = true;
      this.resetClicked = true;
      const body = { hash: this.hash, email: this.userEmail, password: this.password.value };
      this.plistaApiService.changeUserPassword(body).subscribe(
        () => {
          this.processing = false;
          this.resetSuccess = true;
        },
        () => {
          this.processing = false;
          this.resetSuccess = false;
          this.errorMessage = 'Request failed - Something went wrong, please try again later.';
        }
      );
    }
  }

  public navigateToLogin() {
    void this.router.navigate(['/']);
  }

  resend(): void {
    this.resendClicked = true;
    Store.email = this.userEmail;
  }

  get password(): AbstractControl<string> {
    return this.form.get('password');
  }

  get confirmPassword(): AbstractControl<string> {
    return this.form.get('confirm_password');
  }
}
