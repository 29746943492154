export const JOB_TITLE_LIST = [
  {
    label: 'CREATE.ROLE.ROLES.FOUNDER',
    value: 'Founder/Owner'
  },
  {
    label: 'CREATE.ROLE.ROLES.DIGITAL_MARKETER',
    value: 'Digital Marketer'
  },
  {
    label: 'CREATE.ROLE.ROLES.FREELANCE',
    value: 'Freelance'
  },
  {
    label: 'CREATE.ROLE.ROLES.OTHER',
    value: 'Other'
  }
];

export interface JobTitle {
  label: string;
  value: string;
}

export interface BusinessType {
  nameDE: string;
  nameEN: string;
  transKey: string;
}

export interface MarketingObjective {
  nameDE: string;
  nameEN: string;
  transKey: string;
}

export const BUSINESS_TYPE_LIST = [
  {
    nameEN: 'Affiliate',
    nameDE: 'Tochtergesellschaft',
    transKey: 'AFFILIATE'
  },
  {
    nameEN: 'Agency',
    nameDE: 'Agentur',
    transKey: 'AGENCY'
  },
  {
    nameEN: 'Automotive',
    nameDE: 'Automobilindustrie',
    transKey: 'AUTOMOTIVE'
  },
  {
    nameEN: 'Beauty',
    nameDE: 'Beauty',
    transKey: 'BEAUTY'
  },
  {
    nameEN: 'Business Services',
    nameDE: 'Unternehmensdienstleistungen',
    transKey: 'BUSINESS'
  },
  {
    nameEN: 'CPG/FMCG (consumer packaged/fast-moving consumer goods)',
    nameDE: 'Konsumgüter (verpackte Konsumgüter(CPG) /schnelldrehende Konsumgüter(FMCG))',
    transKey: 'CONSUMER'
  },
  {
    nameEN: 'E-commerce',
    nameDE: 'E-commerce',
    transKey: 'ECOMMERCE'
  },
  {
    nameEN: 'Education',
    nameDE: 'Bildung',
    transKey: 'EDUCATION'
  },
  {
    nameEN: 'Entertainment/Media',
    nameDE: 'Unterhaltung/Medien',
    transKey: 'ENTERTAINMENT'
  },
  {
    nameEN: 'Fashion & Apparel',
    nameDE: 'Mode und Bekleidung',
    transKey: 'FASHION'
  },
  {
    nameEN: 'Finance & Insurance',
    nameDE: 'Finanzen und Versicherungen',
    transKey: 'FINANCE'
  },
  {
    nameEN: 'Government, Associations, Non-profits',
    nameDE: 'Regierung, Verbände, Non-Profit-Organisationen',
    transKey: 'GOVERNMENT'
  },
  {
    nameEN: 'Health & Fitness',
    nameDE: 'Gesundheit & Fitness',
    transKey: 'HEALTH'
  },
  {
    nameEN: 'Home/Durable & Services',
    nameDE: 'Haushalt/Gebrauchsgüter & Dienstleistungen',
    transKey: 'HOME'
  },
  {
    nameEN: 'Legal Services/Public Sector',
    nameDE: 'Rechtliche Dienstleistungen/öffentlicher Dienst',
    transKey: 'LEGAL'
  },
  {
    nameEN: 'Manufacturing & Energy',
    nameDE: 'Produktion & Energie',
    transKey: 'MANUFACTURING'
  },
  {
    nameEN: 'Marketing & Advertising Services',
    nameDE: 'Marketing & Werbedienstleistungen',
    transKey: 'MARKETING'
  },
  {
    nameEN: 'Restaurants',
    nameDE: 'Gastronomie',
    transKey: 'RESTAURANTS'
  },
  {
    nameEN: 'Retail',
    nameDE: 'Einzelhandel',
    transKey: 'RETAIL'
  },
  {
    nameEN: 'Technology',
    nameDE: 'Technologie',
    transKey: 'TECHNOLOGY'
  },
  {
    nameEN: 'Telecommunications',
    nameDE: 'Telekommunikation',
    transKey: 'TELECOMMUNICATIONS'
  },
  {
    nameEN: 'Transportation & Logistics',
    nameDE: 'Transport und Logistik',
    transKey: 'TRANSPORTATION'
  },
  {
    nameEN: 'Travel & Hospitality',
    nameDE: 'Reisen und Gastgewerbe',
    transKey: 'TRAVEL'
  }
];

export const OTHER = {
  nameEN: 'Other',
  nameDE: 'Sonstige',
  transKey: 'OTHER'
};

export const CAMPAIGN_GOALS = ['Lead Generation', 'Conversion', 'Online Purchases', 'Brand Awareness', 'Website Visits'];

export const MARKETING_OBJECTIVE_LIST = [
  {
    nameEN: 'Brand Awareness',
    nameDE: 'Brand Awareness',
    transKey: 'BRAND_AWARENESS'
  },
  {
    nameEN: 'Conversion',
    nameDE: 'Conversion',
    transKey: 'CONVERSION'
  },
  {
    nameEN: 'Lead Generation',
    nameDE: 'Leadgenerierung',
    transKey: 'LEAD_GENERATION'
  },
  {
    nameEN: 'Online Purchases',
    nameDE: 'Online Einkäufe',
    transKey: 'ONLINE_PURCHASES'
  },
  {
    nameEN: 'Website Visits',
    nameDE: 'Websitebesuche',
    transKey: 'WEBSITE_VISITS'
  }
];

export const MARKETING_OBJECTIVES = ['BRAND_AWARENESS', 'CONVERSION', 'LEAD_GENERATION', 'ONLINE_PURCHASES', 'WEBSITE_VISITS'];

export const INDIA_CITY_LIST = [
  'AGRA',
  'AHMEDABAD',
  'ALIGARH',
  'BANGALORE',
  'BAREILY',
  'CHANDIGARH',
  'CHENNAI',
  'DELHI',
  'FARIDABAD',
  'GHAZIABAD',
  'GURUGRAM',
  'GUWAHATI',
  'HYDERABAD',
  'INDORE',
  'JAIPUR',
  'KANPUR',
  'KOLKATA',
  'KOTA',
  'LUCKNOW',
  'LUDHIANA',
  'MEERUT',
  'MUMBAI',
  'NAGPUR',
  'NOIDA',
  'PUNE',
  'RAJKOT',
  'VADODRA'
];
