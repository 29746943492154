<lo-mfa-code
  *ngIf="mode===MODE_OF_VERIFICATION.AUTH_APP;else recoveryMode"
  (onConfirm)="codeConfirmed()"
  (onUseAlternative)="useAlternative($event)"
  (onTokenExpired)="tokenExpired()"
  id="auth-code-page"
></lo-mfa-code>
<ng-template #recoveryMode>
  <lo-mfa-recovery (onConfirm)="codeConfirmed()" (onUseAlternative)="useAlternative($event)" (onTokenExpired)="tokenExpired()" id="recovery-code-page"></lo-mfa-recovery>
</ng-template>
